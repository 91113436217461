import { Router } from '@angular/router';

const UpdateListComponent = {
  template: /* html */ `<div class="update-list">
  <div class="page-header" layout="row">
    <span flex>Platform Updates</span>
    <md-button class="md-raised" router-link="/updates/new">Add update</md-button>
  </div>
  <div class="page-body" md-whiteframe="4" layout="column">
    <md-toolbar class="md-accent" layout="row">
      <div class="md-toolbar-tools">
        <md-menu md-position-mode="target-right target">
          <md-button aria-label="Open settings menu" class="md-icon-button" ng-click="$mdMenu.open($event)">
            <md-icon>
              <svg id="dots-vertical" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z"
                />
              </svg>
            </md-icon>
          </md-button>
          <md-menu-content>
            <md-menu-item>
              <md-button ng-click="$ctrl.loadUpdates()">
                <div layout="row" flex>

                  <md-icon md-menu-align-target>
                    <svg id="refresh" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M17.65,6.35C16.2,4.9 14.21,4 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20C15.73,20 18.84,17.45 19.73,14H17.65C16.83,16.33 14.61,18 12,18A6,6 0 0,1 6,12A6,6 0 0,1 12,6C13.66,6 15.14,6.69 16.22,7.78L13,11H20V4L17.65,6.35Z"
                      />
                    </svg>
                  </md-icon>
                  <p flex>Refresh</p>
                </div>
              </md-button>
            </md-menu-item>
          </md-menu-content>
        </md-menu>
      </div>
    </md-toolbar>
    <md-list>
      <md-list-item
        class="lv-item"
        ng-repeat="item in $ctrl.updates"
        ng-click="$ctrl.edit(item.platformUpdateMessageId)"
      >
        <div class="media-body">
          <div class="lv-title">{{item.title}}</div>
          <small class="lv-small">{{item.body|htmlToPlainText|characters:200}}</small>

          <ul class="lv-attrs">
            <li>Date: {{item.publishDate | date:'medium'}}</li>
            <li>Published: {{item.active?'Yes':'No'}}</li>
            <li>Author: {{item.publishedBy}}</li>
          </ul>
        </div>
      </md-list-item>
      <md-list-item layout-align="center" class="single-message" ng-if="$ctrl.loadingUpdates"> Loading </md-list-item>
      <md-list-item
        layout-align="center"
        class="single-message"
        ng-if="$ctrl.updates.length == 0 && $ctrl.loadingUpdates == false"
      >
        No updates yet! <a router-link="/updates/new">Get started now!</a>
      </md-list-item>
    </md-list>
  </div>
</div>
`,
  controller: [
    '$http',
    '$router',
    function ($http: angular.IHttpService, $router: Router): void {
      const $ctrl = this;

      $ctrl.deploymentUrl = window.campaign.environment.urlPrefix.replace('.', '-') || 'www';

      $ctrl.selectedIndex = null;
      $ctrl.loadingUpdates = false;

      $ctrl.edit = (updateId: number) => {
        $router.navigate(['updates', updateId]);
      };

      $ctrl.loadUpdates = function () {
        // get articles
        $ctrl.loadingUpdates = true;
        $http.get('/api/platformUpdates/updates').then(function (resp) {
          $ctrl.updates = resp.data;
          $ctrl.loadingUpdates = false;
        });
      };

      $ctrl.loadUpdates();
    }
  ]
};

export default UpdateListComponent;
