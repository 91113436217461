import { EVENT_DATE_FORMAT, EVENT_DATE_ONLY_FORMAT, EVENT_TIME_ONLY_FORMAT } from '@ih/constants';
import { ImgAlt } from '@ih/interfaces';
import { EmojiService, ScriptService } from '@ih/services';
import { formatTimeAgo } from '@ih/time';
import { format } from 'date-fns';
import { md5 } from 'js-md5';
import { noop } from 'rxjs';
const tagsToReplace = {
  '<': '&lt;',
  '>': '&gt;'
};

export function EscapeFilter(): angular.IFilterFunction {
  return encodeURIComponent;
}

ToTrustedUrlFilter.$inject = ['$sce'];
export function ToTrustedUrlFilter($sce: angular.ISCEService): angular.IFilterFunction {
  return function (text: string): any {
    return $sce.trustAsResourceUrl(text);
  };
}

ToTrustedHtmlFilter.$inject = ['$sce'];
export function ToTrustedHtmlFilter($sce: angular.ISCEService): angular.IFilterFunction {
  return function (html: string): any {
    return $sce.trustAsHtml(html);
  };
}

export function GravatarFilter(): angular.IFilterFunction {
  return function (email: string, size: number): string {
    size = size || 30;
    return '//www.gravatar.com/avatar/' + md5(email || '') + '?s=' + size + '&d=mp&r=g';
  };
}

export function FromNowFilter(): angular.IFilterFunction {
  return function (date: string | number | Date): string {
    // if the date is already a number we don't need to do anything
    if (typeof date !== 'number') {
      date = Date.parse(date as string);
      // check if the parsing failed
      if (isNaN(date)) {
        return 'Never';
      }
    }

    return formatTimeAgo((date as number) - new Date().getTime());
  };
}

export function BlankIfNullFilter() {
  return function (obj: string): string {
    return obj == null || obj === '' ? '<<blank>>' : obj;
  };
}

HighlightFilter.$inject = ['$sce'];
export function HighlightFilter(): angular.IFilterFunction {
  return function (text: string, isSearch: boolean, tag: string) {
    if (text) {
      if (isSearch) {
        tag = tag || 'strong';
        return text.replace(/\*([^*]+)\*/gi, '<' + tag + '>$1</' + tag + '>');
      } else {
        return text;
      }
    }
  };
}

PhoneFilter.$inject = ['$script'];
export function PhoneFilter($script: ScriptService): angular.IFilterFunction {
  let loaded = false;
  let loading = false;
  function phoneFilter(phone: string): string {
    if (!loaded) {
      if (loading) {
        return phone;
      }
      loading = true;
      $script.loadIntlTelInput().subscribe(() => {
        loaded = true;
        loading = false;
      });
      return phone;
    }
    if (phone) {
      return window.intlTelInputUtils.formatNumber(phone, null, window.intlTelInputUtils.numberFormat.NATIONAL);
    }
  }
  phoneFilter.$stateful = true;

  return phoneFilter;
}

export function ToSrcSetFilter(): angular.IFilterFunction {
  return function (imgAlts: ImgAlt[]): string {
    if (!imgAlts) return null;

    if (!Array.isArray(imgAlts)) throw new Error('toSrcSet expects an array got: ' + typeof imgAlts);

    return imgAlts
      .map((imgAlt) => {
        return `${imgAlt.url} ${imgAlt.width}w`;
      })
      .join(', ');
  };
}

export function HtmlEncodeSimpleFilter(): angular.IFilterFunction {
  return function (html: string): string {
    if (html) {
      return html.replace(/[<>]/g, function (tag) {
        return tagsToReplace[tag] || tag;
      });
    }
    return null;
  };
}

export function LbToBrFilter(): angular.IFilterFunction {
  return function (html: string): string {
    if (!html) {
      return null;
    }
    return html.replace(/(?:\r\n|\r|\n)/g, '<br>');
  };
}

export function SplitCharactersFilter(): angular.IFilterFunction {
  return function (input: string, chars: number): string {
    if (isNaN(chars)) return input;
    if (chars <= 0) return '';
    if (input && input.length > chars) {
      const prefix = input.substring(0, chars / 2);
      const postfix = input.substring(input.length - chars / 2, input.length);
      return prefix + '...' + postfix;
    }
    return input;
  };
}

export function WordsFilter(): angular.IFilterFunction {
  return function (input: string, words: number): string {
    if (isNaN(words)) return input;
    if (words <= 0) return '';
    if (input) {
      const inputWords = input.split(/\s+/);
      if (inputWords.length > words) {
        input = inputWords.slice(0, words).join(' ') + '…';
      }
    }
    return input;
  };
}

export function AppUrlFilter(): angular.IFilterFunction {
  return function (slug: string): string {
    return 'https://' + slug + window.campaign.environment.urlPrefix + '.' + window.campaign.domains.app;
  };
}

export function HtmlToPlainTextFilter(): angular.IFilterFunction {
  return function (text: string): string {
    return text ? String(text).replace(/<[^>]+>/gm, '') : '';
  };
}

export function VideoUrlFilter($sce: angular.ISCEService): angular.IFilterFunction {
  return function (url: string, providerTypeId: number, autoPlay: boolean): any {
    if (url) {
      switch (providerTypeId) {
        case 1:
          return $sce.trustAsResourceUrl(
            url.replace('youtube', 'youtube-nocookie') + '&enablejsapi=1' + (autoPlay ? '&autoplay=1' : '')
          );
        case 2:
          return $sce.trustAsResourceUrl(url + '?api=1&player_id=video-player' + (autoPlay ? '&autoplay=1' : ''));
        default:
          throw new Error('You must specify a valid providerId');
      }
    }
  };
}
VideoUrlFilter.$inject = ['$sce'];

export function FormatEventDateFilter(): angular.IFilterFunction {
  return function (date: string): string {
    if (date) {
      return format(new Date(date), EVENT_DATE_FORMAT);
    } else {
      return '';
    }
  };
}

export function FormatEventDateOnlyFilter(): angular.IFilterFunction {
  return function (date: string): string {
    if (date) {
      return format(new Date(date), EVENT_DATE_ONLY_FORMAT);
    } else {
      return '';
    }
  };
}

export function FormatEventTimeOnlyFilter(): angular.IFilterFunction {
  return function (date: string): string {
    if (date) {
      return format(new Date(date), EVENT_TIME_ONLY_FORMAT);
    } else {
      return '';
    }
  };
}

export function DurationFilter(): angular.IFilterFunction {
  return function (count: number): string {
    if (count) {
      // if the video is longer than 1 hour then use the hour format
      if (count >= 60 * 60) {
        return format(count * 1000, 'h:mm:ss');
      } else {
        return format(count * 1000, 'm:ss');
      }
    } else {
      return '';
    }
  };
}

export function ThousandSuffixFilter(): angular.IFilterFunction {
  return function (input: number, decimals: number): string {
    const suffixes = ['k', 'M', 'G', 'T', 'P', 'E'];

    if (isNaN(input)) {
      return null;
    }

    if (input < 1000) {
      return input.toString();
    }

    const exp = Math.floor(Math.log(input) / Math.log(1000));

    return (input / Math.pow(1000, exp)).toFixed(decimals) + suffixes[exp - 1];
  };
}

export function FormatBytesFilter(): angular.IFilterFunction {
  return function (bytes: number, decimals: number) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals || (bytes > 1000000 ? 2 : 0);
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };
}

export function EmojiFilter($emoji: EmojiService, $timeout: angular.ITimeoutService): (value: string) => string {
  const filter = function (value: string): string {
    if (!value) {
      return '';
    }

    // if the service has not been initialized, return the value without emoji parsed
    if (!$emoji.initialized) {
      // run init() to initialize the service
      $emoji.init().then(() => {
        // the service has initialized so force change detection
        $timeout(noop);
      });

      // return the value without emoji parsed
      return value;
    }

    return $emoji.replaceColons(value, 20);
  };

  filter.$stateful = true;
  return filter;
}
EmojiFilter.$inject = ['$emoji', '$timeout'];
