import { Router } from '@angular/router';
import { LazySnackBarService } from '@ih/services';

const UpdateDetailComponent = {
  bindings: {
    platformUpdateMessageId: '<'
  },

  template: /* html */ `<div class="update-detail">
  <div class="page-body updates-item" md-whiteframe="4" layout="column">
    <md-toolbar class="md-accent" layout="row">
      <div class="md-toolbar-tools">
        <span flex>Edit update</span>
        <md-button class="md-icon-button" ng-click="$ctrl.delete(update)">
          <md-icon>
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
            </svg>
          </md-icon>
        </md-button>
      </div>
    </md-toolbar>
    <div md-whiteframe="4">
      <form name="platformUpdate" ng-submit="$ctrl.saveUpdate(update)" layout="column" layout-padding>
        <md-switch ng-model="$ctrl.update.active" aria-label="Published"> Published </md-switch>
        <md-input-container>
          <label for="title">Title</label>
          <input type="text" name="title" id="title" required ng-model="$ctrl.update.title" />
          <small
            class="has-error"
            ng-if="platformUpdate.title.$error.required && (platformUpdate.title.$dirty || platformUpdate.$submitted)"
          >
            OOPS! Looks like you forgot to enter your title.
          </small>
        </md-input-container>
        <div>
          <h4>Content</h4>
          <ih-froala
            content-type="system"
            name="body"
            ng-model="$ctrl.update.body"
            [allow-file-upload]="false"
            [allow-image-upload]="false"
            [allow-video-upload]="false"
            [disable-sanitizer]="true"
            [toolbar-sticky-offset]="128"
            required
          ></ih-froala>
          <small
            class="has-error"
            ng-if="platformUpdate.body.$error.required && (platformUpdate.body.$dirty || platformUpdate.$submitted)"
          >
            OOPS! Looks like you forgot to enter your content.
          </small>
        </div>
        <div style="text-align: right">
          <md-button type="button" ng-click="$ctrl.back()">Back</md-button>
          <md-button type="submit" class="md-raised md-primary" ng-disabled="savingUpdate">Save changes </md-button>
        </div>
      </form>
    </div>
  </div>
</div>
`,
  controller: [
    '$http',
    '$lazySnackbar',
    '$router',
    function ($http: angular.IHttpService, $lazySnackbar: LazySnackBarService, $router: Router) {
      const $ctrl = this;

      $ctrl.$onInit = function () {
        if ($ctrl.platformUpdateMessageId === 'new') {
          $ctrl.update = {};
        } else {
          $http.get('/api/platformUpdates/updates/' + $ctrl.platformUpdateMessageId).then(
            function (resp) {
              $ctrl.update = resp.data;
            },
            function () {
              $lazySnackbar.open('There was a problem getting this update');
            }
          );
        }
      };

      $ctrl.back = function () {
        $router.navigate(['updates']);
      };

      $ctrl.delete = function () {
        $http.delete('/api/platformUpdates/updates/' + $ctrl.platformUpdateMessageId).then(
          function () {
            $router.navigate(['updates']);
          },
          function () {
            $lazySnackbar.open('There was a problem saving your update');
          }
        );
      };

      $ctrl.saveUpdate = function () {
        $ctrl.savingUpdate = true;
        if (!$ctrl.update.platformUpdateMessageId) {
          $http.post('/api/platformUpdates/updates', $ctrl.update).then(
            function () {
              $ctrl.savingUpdate = false;
              $router.navigate(['updates']);
            },
            function () {
              $ctrl.savingUpdate = false;
              $lazySnackbar.open('There was a problem creating your update');
            }
          );
        } else {
          $http.put('/api/platformUpdates/updates/' + $ctrl.platformUpdateMessageId, $ctrl.update).then(
            function () {
              $ctrl.savingUpdate = false;
              $router.navigate(['updates']);
            },
            function () {
              $ctrl.savingUpdate = false;
              $lazySnackbar.open('There was a problem saving your update');
            }
          );
        }
      };
    }
  ]
};

export default UpdateDetailComponent;
