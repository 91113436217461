import { LazySnackBarService } from '@ih/services';
import { wrapSvg } from '@ih/utilities';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import each from 'lodash-es/each';
import map from 'lodash-es/map';
import merge from 'lodash-es/merge';
import { SideNavService } from '../../side-nav.service';

const SmsLogComponent = {
  bindings: {
    campaignId: '<'
  },

  template: /* html */ `<iframe id="export" style="display: none" ng-src="{{$ctrl.exportUrl}}"></iframe>
<section class="app-sms-log">
  <header class="app-sms-log-header bridge-header sticky-header" layout="row" layout-align="start center">
    <div class="pull-left" layout="row" layout-align="start center" flex>
    <md-icon class="app-sms-log-icon">
      <svg viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M13 11H11V5H13M13 15H11V13H13M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2Z"
        />
      </svg>
    </md-icon>
      <div class="app-sms-log-title">SMS log</div>
      <span flex></span>
      <div layout="row" layout-align="end" class="button-controls">
        <md-button class="md-raised" ng-click="$ctrl.search()"> Refresh </md-button>
        <md-button class="md-raised md-primary" target="_blank" ng-click="$ctrl.getExport()"> Export </md-button>
      </div>
    </div>
  </header>

  <div class="app-sms-log-container">
    <div class="page-body app-sms-log-page-body" layout="column">
      <div layout-padding ng-if="$ctrl.campaign">
        <!-- SMS Log Filters -->
        <div class="logFilter" layout="row" layout-align="center center" flex>
          <span flex>
            <md-input-container>
              <label>Start Date</label>
              <md-datepicker ng-model="$ctrl.filterStartDate" md-placeholder="Enter date" ng-change="$ctrl.search()">
              </md-datepicker>
            </md-input-container>
          </span>
          <span flex>
            <md-input-container>
              <label>End Date</label>
              <md-datepicker ng-model="$ctrl.filterEndDate" md-placeholder="Enter date" ng-change="$ctrl.search()">
              </md-datepicker>
            </md-input-container>
          </span>
          <span flex>
            <md-input-container class="channel-picker-container">
              <label class="channel-label md-container-ignore">Channel</label>
              <channel-picker
                ng-model="$ctrl.groupList"
                ng-required="true"
                name="channelPicker"
                content-type="SMS Message"
                ng-disabled="$ctrl.smsType == 1 || $ctrl.smsType == 3"
                campaign-id="{{$ctrl.campaign.campaign_id}}"
                on-change="$ctrl.search()"
              ></channel-picker>
            </md-input-container>
          </span>
          <span flex class="type-container">
            <md-input-container>
              <label>Type</label>
              <md-select ng-model="$ctrl.smsType" ng-change="$ctrl.search()">
                <md-option ng-value="0">All</md-option>
                <md-option ng-value="1">Verification</md-option>
                <md-option ng-value="2">Post</md-option>
                <md-option ng-value="3">Donation</md-option>
                <md-option ng-value="4">System</md-option>
              </md-select>
            </md-input-container>
          </span>
        </div>

        <!-- SMS Log Search -->
        <div class="app-sms-log-search-row" layout="row">
          <span flex>
            <md-input-container class="md-icon-right" layout="row" flex>
              <input
                flex
                id="txtFind"
                type="text"
                ng-model="$ctrl.query"
                name="x"
                placeholder="Search log..."
                ng-change="$ctrl.search()"
                ng-model-options="{updateOn: 'default blur',debounce: { 'default': 500, 'blur': 0 }}"
              />
              <md-icon ng-click="$ctrl.clearSearch()">
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
                  />
                </svg>
              </md-icon>
            </md-input-container>
          </span>
        </div>

        <md-divider class="app-sms-log-md-divider"></md-divider>

        <md-list
          infinite-scroll="$ctrl.getMore()"
          infinite-scroll-immediate-check="false"
          infinite-scroll-distance="3"
          infinite-scroll-disabled="$ctrl.busy || $ctrl.noMore"
        >
          <md-list-item flex layoutwrap class="logListHeading">
            <a href="" ng-click="$ctrl.changePredicate('createdAt')" class="smsLogHeading smsLogDate" flex="15">
              Date
              <md-icon
                ng-bind-html="$ctrl.predicate.icon | toTrustedHtml"
                ng-if="$ctrl.predicate.field=='createdAt'"
              ></md-icon>
            </a>
            <a href="" ng-click="$ctrl.changePredicate('channelNames')" class="smsLogHeading smsLogChannels" flex="15">
              Channels
              <md-icon
                ng-bind-html="$ctrl.predicate.icon | toTrustedHtml"
                ng-if="$ctrl.predicate.field=='channelNames'"
              ></md-icon>
            </a>
            <a href="" ng-click="$ctrl.changePredicate('smsType')" class="smsLogHeading smsLogType" flex="10">
              Type
              <md-icon
                ng-bind-html="$ctrl.predicate.icon | toTrustedHtml"
                ng-if="$ctrl.predicate.field=='smsType'"
              ></md-icon>
            </a>
            <a href="" ng-click="$ctrl.changePredicate('body')" class="smsLogHeading smsLogMessage" flex="25">
              Message Body
              <md-icon
                ng-bind-html="$ctrl.predicate.icon | toTrustedHtml"
                ng-if="$ctrl.predicate.field=='body'"
              ></md-icon>
            </a>
            <a
              href=""
              ng-click="$ctrl.changePredicate('targetPhone')"
              class="smsLogHeading smsLogRecipientNumber"
              flex="15"
            >
              Recipient #
              <md-icon
                ng-bind-html="$ctrl.predicate.icon | toTrustedHtml"
                ng-if="$ctrl.predicate.field=='targetPhone'"
              ></md-icon>
            </a>
            <a
              href=""
              ng-click="$ctrl.changePredicate('receiverName')"
              class="smsLogHeading smsLogRecipientName"
              flex="10"
            >
              Name
              <md-icon
                ng-bind-html="$ctrl.predicate.icon | toTrustedHtml"
                ng-if="$ctrl.predicate.field=='receiverName'"
              ></md-icon>
            </a>
          </md-list-item>
          <md-list-item flex layoutwrap class="logListItem" ng-repeat="log in $ctrl.smsLogs">
            <span class="smsLogDate" flex="15"> {{log.createdAt | date:'medium'}} </span>
            <span class="smsLogChannels" flex="15"> {{log.channelNames || '-'}} </span>
            <span class="smsLogType" flex="10"> {{log.smsType}} </span>
            <span class="smsLogMessage" flex="25"> {{log.body}} </span>
            <span class="smsLogRecipientNumber" flex="15"> {{log.targetPhone | phone}} </span>
            <span class="smsLogRecipientName" flex="10"> {{log.receiverName}} </span>
          </md-list-item>
          <md-list-item
            flex
            layoutwrap
            style="text-align: center"
            class="logListItem"
            ng-if="$ctrl.smsLogs.length==0 && !$ctrl.busy"
          >
            <span class="notFound" flex="100">There are no SMS logs found.</span>
          </md-list-item>
        </md-list>
      </div>
    </div>
  </div>
</section>
`,
  controller: [
    '$http',
    '$q',
    '$lazySnackbar',
    '$sideNavService',
    function (
      $http: angular.IHttpService,
      $q: angular.IQService,
      $lazySnackbar: LazySnackBarService,
      $sideNavService: SideNavService
    ) {
      const $ctrl = this;

      $ctrl.predicate = {
        field: 'createdAt',
        icon: wrapSvg(mdiChevronDown)
      };
      $ctrl.smsLogs = [];
      $ctrl.searching = false;
      $ctrl.showFilter = false;
      $ctrl.filterDate = new Date();
      $ctrl.groupList = [];
      $ctrl.smsType = 0;
      $ctrl.itemCounter = 0;
      $ctrl.query = '';

      $ctrl.$onInit = function () {
        $sideNavService.setCurrentApp($ctrl.campaignId);
        $http.get('/api/campaigns/' + $ctrl.campaignId).then((resp) => {
          $ctrl.campaign = resp.data;
          $ctrl.getMore();
        });
      };
      $ctrl.filterToggle = function () {
        $ctrl.showFilter = !$ctrl.showFilter;
        if ($ctrl.showFilter) $ctrl.smsType = 0;
      };
      $ctrl.changePredicate = function (predicate) {
        if ($ctrl.predicate.field === predicate) {
          $ctrl.predicate.icon =
            $ctrl.predicate.icon === wrapSvg(mdiChevronUp) ? wrapSvg(mdiChevronDown) : wrapSvg(mdiChevronUp);
        } else {
          $ctrl.predicate = {
            field: predicate,
            icon: wrapSvg(mdiChevronUp)
          };
        }
        $ctrl.resetInfiniteScroll();
        $ctrl.getMore();
      };
      $ctrl.resetInfiniteScroll = function () {
        $ctrl.itemCounter = 0;
        $ctrl.noMore = false;
        $ctrl.busy = false;
        $ctrl.smsLogs = [];
        // if verfication or donation is selected then reset the group list
        if ($ctrl.smsType === 1 || $ctrl.smsType === 3) {
          $ctrl.groupList = [];
        }
      };

      $ctrl.search = function () {
        $ctrl.searching = true;
        $ctrl.resetInfiniteScroll();
        $ctrl.getMore();
      };

      $ctrl.clearSearch = function () {
        if ($ctrl.searching) {
          $ctrl.searching = false;
          $ctrl.query = '';
          $ctrl.resetInfiniteScroll();
          $ctrl.getMore();
        }
      };

      $ctrl.filterSMS = function () {
        $ctrl.resetInfiniteScroll();
        $ctrl.getMore();
      };

      function serialize(obj) {
        const str = [];
        for (const p in obj) {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
        return str.join('&');
      }

      let canceler;
      const opts = {};

      $ctrl.getMore = function () {
        if (canceler) {
          canceler.resolve();
        }

        if ($ctrl.busy || $ctrl.noMore) return;
        canceler = $q.defer();

        $ctrl.busy = true;
        // load campaign data
        $http
          .get<any>('/api/sms/' + $ctrl.campaign.campaign_id, {
            params: merge(
              {
                q: $ctrl.query,
                skip: $ctrl.itemCounter,
                limit: 50,
                sort: $ctrl.predicate.field,
                ascending: $ctrl.predicate.icon === wrapSvg(mdiChevronUp),
                smsType: $ctrl.smsType,
                startDate: $ctrl.filterStartDate ? $ctrl.filterStartDate : null,
                endDate: $ctrl.filterEndDate ? $ctrl.filterEndDate : null,
                searchChannelIds: map($ctrl.groupList, 'channelId').join(',')
              },
              opts
            ),
            timeout: canceler.promise
          })
          .then(
            function (resp) {
              $ctrl.busy = false;
              if (resp.data.length === 0) $ctrl.noMore = true;

              $ctrl.itemCounter += resp.data.length;
              each(resp.data, function (log) {
                log.channelNames = log.channelNames.join(', ');
              });
              $ctrl.smsLogs = $ctrl.smsLogs.concat(resp.data);
            },
            function (error) {
              console.error(error);

              $ctrl.busy = false;

              $lazySnackbar.open('Unable to get sms detail');
            }
          );
      };

      $ctrl.getExport = () => {
        $ctrl.exportUrl =
          `/api/sms/${$ctrl.campaign.campaign_id}/export?` +
          serialize(
            merge(
              {
                q: $ctrl.query,
                sort: $ctrl.predicate.field,
                ascending: $ctrl.predicate.icon === 'chevron-up',
                smsType: $ctrl.smsType,
                startDate: $ctrl.filterStartDate ? $ctrl.filterStartDate.toISOString() : '',
                endDate: $ctrl.filterEndDate ? $ctrl.filterEndDate.toISOString() : '',
                searchChannelIds: map($ctrl.groupList, 'channelId').join(',')
              },
              opts
            )
          );
      };
    }
  ]
};

export default SmsLogComponent;
