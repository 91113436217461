import { Router } from '@angular/router';

const ResponseDetailComponent = {
  bindings: {
    responseId: '<'
  },

  template: /* html */ `<div class="response-detail">
  <div class="page-body" md-whiteframe="4" layout="column">
    <md-toolbar class="md-accent" layout="row">
      <div class="md-toolbar-tools">
        <span flex>Edit response</span>
      </div>
    </md-toolbar>
    <div md-whiteframe="4">
      <form name="responseForm" ng-submit="$ctrl.saveResponse()" layout="column" layout-padding>
        <md-input-container>
          <label for="trigger">Triggers</label>
          <textarea
            type="text"
            name="trigger"
            id="trigger"
            placeholder="when someone says..."
            required
            ng-model="$ctrl.newResponse.trigger"
          ></textarea>
          <small
            class="text-danger"
            ng-if="responseForm.trigger.$error.required && (responseForm.trigger.$dirty || responseForm.$submitted)"
          >
            OOPS! Looks like you forgot to enter your trigger.
          </small>
        </md-input-container>
        <md-input-container>
          <label for="response">Response</label>
          <textarea
            type="text"
            name="response"
            id="response"
            placeholder="mrmeeseeks responds..."
            required
            ng-model="$ctrl.newResponse.response"
          ></textarea>
          <small
            class="text-danger"
            ng-if="responseForm.response.$error.required && (responseForm.response.$dirty || responseForm.$submitted)"
          >
            OOPS! Looks like you forgot to enter your response.
          </small>
        </md-input-container>
        <md-input-container>
          <label for="probability">Probability</label>
          <input
            type="number"
            name="probability"
            min="1"
            max="100"
            id="probability"
            placeholder="0-100"
            required
            ng-model="$ctrl.newResponse.probability"
          />
          <small
            class="text-danger"
            ng-if="responseForm.probability.$error.required && (responseForm.probability.$dirty || responseForm.$submitted)"
          >
            OOPS! Looks like you forgot to enter your probability.
          </small>
          <small class="text-danger" ng-if="$ctrl.newResponse.probability >= 50">
            That seems kinda high.... are you sure about that <strong><em>Karolyn</em></strong
            >? <a target="_blank" href="https://youtu.be/rfh4Mhp-a6U">https://youtu.be/rfh4Mhp-a6U</a>
          </small>
          <small
            class="text-danger"
            ng-if="responseForm.probability.$error.number && (responseForm.probability.$dirty || responseForm.$submitted)"
          >
            Sorry that doesn't look like a valid probability
          </small>
        </md-input-container>
        <div style="text-align: right">
          <md-button type="button" router-link="/responses">Back</md-button>
          <md-button type="submit" class="md-raised md-primary" ng-disabled="$ctrl.savingResponse"
            >Save changes
          </md-button>
        </div>
      </form>
    </div>
  </div>
</div>
`,
  controller: [
    '$http',
    '$router',
    function ($http: angular.IHttpService, $router: Router) {
      const $ctrl = this;

      $ctrl.$onInit = function () {
        if ($ctrl.responseId === 'new') {
          $ctrl.newResponse = {};
        } else {
          $http.get('/api/responses/' + $ctrl.responseId).then(function (resp) {
            $ctrl.newResponse = resp.data;
          });
        }
      };

      $ctrl.saveResponse = function () {
        $ctrl.savingResponse = true;
        if (!$ctrl.newResponse.slackResponseId) {
          $http.post('/api/responses', $ctrl.newResponse).then(
            function (resp) {
              $ctrl.savingResponse = false;
              $router.navigate(['responses']);
            },
            function (resp) {
              $ctrl.savingResponse = false;
              alert('There was a problem saving the article');
            }
          );
        } else {
          $http.put('/api/responses/' + $ctrl.newResponse.slackResponseId, $ctrl.newResponse).then(
            function (resp) {
              $ctrl.savingResponse = false;
              $router.navigate(['responses']);
            },
            function (resp) {
              $ctrl.savingResponse = false;
              alert('There was a problem saving the article');
            }
          );
        }
      };
    }
  ]
};

export default ResponseDetailComponent;
