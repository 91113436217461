import { Router } from '@angular/router';
import { wrapSvg } from '@ih/utilities';
import { mdiArchive, mdiRun } from '@mdi/js';
import { isBefore } from 'date-fns';
import { CreateAppDialogService } from '../../create-app-dialog/create-app-dialog.service';

const AppListComponent = {
  template: /* html */ `<div class="app-list">
  <div class="page-header" layout="row">
    <span flex>HUBs</span>
    <md-button class="md-raised md-primary" ng-click="$ctrl.showAddDemo()">Create HUB</md-button>
  </div>
  <div class="page-body"
       md-whiteframe="4"
       layout="column">
    <md-toolbar class="md-accent"
                layout="column">
      <div class="flex app-tab-padding"
           layout="row">
        <md-tabs class="md-accent"
                 md-selected
                 flex>
          <md-tab md-on-select="$ctrl.toggle('clientApp')">
            <md-tab-label>
              <span>Client HUBs</span>
              <span class="summary-badge">{{$ctrl.summary[0]}}</span>
            </md-tab-label>
          </md-tab>

          <md-tab md-on-select="$ctrl.toggle('demo')">
            <span>Demo HUBs</span>
            <span class="summary-badge">{{$ctrl.summary[2]}}</span>
          </md-tab>

          <md-tab md-on-select="$ctrl.toggle('testApp')">
            <span>Test HUBs</span>
            <span class="summary-badge">{{$ctrl.summary[1]}}</span>
          </md-tab>

          <md-tab md-on-select="$ctrl.toggle('allApps')">
            <span>All HUBs</span>
            <span class="summary-badge">{{$ctrl.summary[3]}}</span>
          </md-tab>
        </md-tabs>

        <md-menu md-position-mode="target-right target">
          <md-button aria-label="Open settings menu" class="md-icon-button" ng-click="$mdMenu.open($event)">
              <md-icon md-menu-origin>
                <svg id="dots-vertical" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z"
                  />
                </svg>
              </md-icon>
          </md-button>
          <md-menu-content>
            <md-menu-item>
              <md-button ng-click="$ctrl.toggle('archived')">
                <div layout="row" flex>
                  <md-icon
                    md-menu-align-target
                    ng-bind-html="($ctrl.archived?$ctrl.mdiRun:$ctrl.mdiArchive)|toTrustedHtml"
                  >
                  </md-icon>
                  <p flex>Show {{$ctrl.archived?'Active':'Archived'}}</p>
                </div>
              </md-button>
            </md-menu-item>
            <md-menu-item>
              <md-button ng-click="$ctrl.loadApps()">
                <div layout="row" flex>
                  <md-icon md-menu-align-target>
                    <svg id="refresh" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M17.65,6.35C16.2,4.9 14.21,4 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20C15.73,20 18.84,17.45 19.73,14H17.65C16.83,16.33 14.61,18 12,18A6,6 0 0,1 6,12A6,6 0 0,1 12,6C13.66,6 15.14,6.69 16.22,7.78L13,11H20V4L17.65,6.35Z"
                      />
                    </svg>
                  </md-icon>
                  <p flex>Refresh</p>
                </div>
              </md-button>
            </md-menu-item>
          </md-menu-content>
        </md-menu>
      </div>

      <div class="md-toolbar-tools"
           layout="row">
        <md-input-container flex>
          <label>Search for a HUB...</label>
          <input placeholder="Find HUB..."
                 ng-model="$ctrl.query"
                 ng-model-options="{debounce:500}"
                 ng-change="$ctrl.queryChanged()"
                 flex />
        </md-input-container>
        <md-button class="md-icon-button"
                   ng-click="$ctrl.clearQuery()">
          <md-icon>
            <svg id="close"
                 viewBox="0 0 24 24">
              <path fill="currentColor"
                    d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
            </svg>
          </md-icon>
        </md-button>
      </div>
    </md-toolbar>

    <md-list infinite-scroll="$ctrl.loadApps()"
             infinite-scroll-disabled="$ctrl.busy"
             infinite-scroll-distance="3"
             infinite-scroll-immediate-check="false">
      <md-list-item class="lv-item"
                    ng-repeat="app in $ctrl.apps track by app.campaignId"
                    ng-click="$ctrl.goTo('/apps/' + app.campaignId)">
        <div layout="row"
             layout-align="center start">
          <div class="app-icon">
            <ih-image [image-info]="app.icon"
                      [aspect-ratio]="1"
                      [visible]="true"></ih-image>
          </div>
          <div flex
               layout="column">
            <div class="lv-title"
                 ng-if="app.title">{{app.title}}</div>
            <em ng-if="!app.title">untitled</em>
            <small class="lv-small">Owner: <a href="mailto:{{app.owner.email}}">{{app.owner.fullName }}</a></small>
            <small class="lv-small">Url: <a target="_blank"
                 href="{{app.slug | appUrl}}">{{app.slug | appUrl}}</a></small>
            <ul class="lv-attrs">
              <li><strong>Demo:</strong> {{app.demo?'Yes':'No'}}</li>
              <li><strong>Test Hub:</strong> {{app.testApp?'Yes':'No'}}</li>
              <li><strong>Private Hub:</strong> {{app.isPrivate?'Yes':'No'}}</li>
              <li><strong>Campaign ID:</strong> {{app.campaignId}}</li>
              <li><strong>Active members:</strong> {{app.activeUserCount}}</li>
              <li><strong>Created:</strong> {{app.createdAt | date : 'medium' }}</li>
              <li ng-if="!app.active" class="danger">THIS HUB IS DISABLED</li>
            </ul>
          </div>
        </div>
      </md-list-item>
    </md-list>
  </div>
</div>
`,
  controller: [
    '$http',
    '$q',
    '$router',
    '$createApp',
    function (
      $http: angular.IHttpService,
      $q: angular.IQService,
      $router: Router,
      $createApp: CreateAppDialogService
    ): void {
      const $ctrl = this;

      $ctrl.mdiRun = wrapSvg(mdiRun);
      $ctrl.mdiArchive = wrapSvg(mdiArchive);

      $ctrl.$onInit = () => {
        $ctrl.toggle('clientApp');
        $ctrl.getSummary();
      };

      $ctrl.deploymentUrl = window.campaign.environment.urlPrefix;
      $ctrl.buildDeploymentUrl = window.campaign.environment.urlPrefix.replace('.', '-');

      $ctrl.archived = false;
      $ctrl.testApp = null;
      $ctrl.demo = null;
      $ctrl.clientApp = null;

      $ctrl.apps = [];

      let loadAppCanceler;
      $ctrl.loadApps = function () {
        if ($ctrl.busy || $ctrl.noMore) {
          return;
        }

        if (loadAppCanceler) {
          loadAppCanceler.resolve();
        }

        loadAppCanceler = $q.defer();
        $ctrl.busy = true;

        // get list of apps
        $http<unknown[]>({
          method: 'GET',
          url: '/api/campaigns',
          params: {
            query: this.query,
            archived: $ctrl.archived,
            testApp: $ctrl.testApp,
            demo: $ctrl.demo,
            skip: $ctrl.apps.length
          },
          timeout: loadAppCanceler.promise
        })
          .then(function (resp) {
            $ctrl.busy = false;
            if (resp.data.length === 0) {
              $ctrl.noMore = true;
            }
            $ctrl.apps = $ctrl.apps.concat(resp.data);
          })
          .catch(function () {
            $ctrl.busy = false;
          });
      };

      let summaryCanceler;
      $ctrl.getSummary = () => {
        if (summaryCanceler) {
          summaryCanceler.resolve();
        }

        summaryCanceler = $q.defer();

        // get summary based on query
        $ctrl.summary = null;
        $http
          .get('/api/campaigns/summary', {
            params: {
              query: this.query,
              archived: $ctrl.archived
            },
            timeout: loadAppCanceler.promise
          })
          .then((resp) => {
            $ctrl.summary = resp.data;
          });
      };

      $ctrl.queryChanged = () => {
        $ctrl.apps = [];
        $ctrl.noMore = false;
        $ctrl.loadApps();
        $ctrl.getSummary();
      };

      $ctrl.clearQuery = function () {
        $ctrl.query = null;
        $ctrl.apps = [];
        $ctrl.noMore = false;
        $ctrl.loadApps();
        $ctrl.getSummary();
      };

      $ctrl.toggle = function (opt) {
        if (opt === 'archived') {
          $ctrl.archived = !$ctrl.archived;
        }
        if (opt === 'clientApp') {
          $ctrl.testApp = false;
          $ctrl.demo = false;
        }
        if (opt === 'testApp') {
          $ctrl.testApp = true;
          $ctrl.demo = null;
        }
        if (opt === 'demo') {
          $ctrl.testApp = false;
          $ctrl.demo = true;
        }
        if (opt === 'allApps') {
          $ctrl.testApp = null;
          $ctrl.demo = null;
        }
        $ctrl.apps = [];
        $ctrl.noMore = false;
        $ctrl.loadApps();
      };

      $ctrl.trialExpired = function (trialExpirationDate) {
        return !isBefore(new Date(), new Date(trialExpirationDate));
      };

      $ctrl.showAddDemo = async function () {
        const dialog = await $createApp.open();
        dialog.afterClosed().subscribe(() => {
          $ctrl.loadApps();
        });
      };

      $ctrl.goTo = (url: string): void => {
        $router.navigateByUrl(url);
      };
    }
  ]
};

export default AppListComponent;
