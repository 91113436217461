import { AsyncPipe, DOCUMENT, DatePipe, NgFor, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { MemberEditorDialogService } from '@ih/admin';
import { FilterChipsComponent, MemberFilterChipsDirective } from '@ih/filter-chips';
import { ImageComponent } from '@ih/image';
import {
  ListFilterItem,
  ListFilterItemValue,
  MemberFilterOptions,
  MemberListItem,
  SearchResponse,
  UserRole
} from '@ih/interfaces';
import { LazySnackBarService } from '@ih/services';
import { BehaviorSubject, Observable, Subject, merge, of } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { SideNavService } from '../side-nav.service';

@Component({
  selector: 'ih-member-list',
  templateUrl: './member-list.component.html',
  styleUrls: ['./member-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    DatePipe,
    NgFor,
    NgIf,

    MatButtonModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,

    FilterChipsComponent,
    ImageComponent,
    MemberFilterChipsDirective
  ]
})
export class MemberListComponent implements OnInit, AfterViewInit {
  @HostBinding('class.ih-member-list') hostClass = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  search$ = new BehaviorSubject<MemberFilterOptions>({
    fullName: '',
    email: '',
    userRoles: [],
    status: ''
  });

  campaignId: number;

  query = new UntypedFormControl('');

  refresh$ = new Subject<void>();
  users$: Observable<MemberListItem[]>;

  isLoadingResults = false;
  resultsLength = 0;
  reload$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private snackbar: LazySnackBarService,
    private sideNavService: SideNavService,
    private route: ActivatedRoute,
    private memberEditorDialog: MemberEditorDialogService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.campaignId = this.route.snapshot.params.campaignId;
    this.sideNavService.setCurrentApp(this.campaignId);
  }

  ngAfterViewInit(): void {
    this.users$ = merge(this.paginator.page, this.refresh$, this.search$).pipe(
      startWith({}),
      switchMap(() => {
        this.isLoadingResults = true;
        const filter = this.search$.getValue();
        return this.http.get<SearchResponse<MemberListItem>>(`/api/campaigns/${this.campaignId}/users`, {
          params: {
            fullName: filter.fullName ?? '',
            email: filter.email ?? '',
            status: filter.status ?? '',
            defaultRoles:
              '[' +
              filter.userRoles
                .filter((r) => r.immutable)
                .map((r) => r.userRoleId)
                .join(',') +
              ']',
            customRoles:
              '[' +
              filter.userRoles
                .filter((r) => !r.immutable)
                .map((r) => r.userRoleId)
                .join(',') +
              ']',
            page: this.paginator.pageIndex.toString(),
            limit: this.paginator.pageSize.toString()
          }
        });
      }),
      map((data) => {
        // Flip flag to show that loading has finished.
        this.isLoadingResults = false;
        this.resultsLength = data.hits;
        this.cd.markForCheck();

        return data.results;
      }),
      catchError((err) => {
        console.log(err);
        this.snackbar.open('There was a problem getting members from the server');
        this.isLoadingResults = false;
        this.cd.markForCheck();

        return of([]);
      })
    );
  }

  changePage(): void {
    this.document.documentElement.scrollTo(0, 0);
  }

  resetPaging(): void {
    this.paginator.pageIndex = 0;
  }

  filterChanged(filters: ListFilterItem[]): void {
    this.resetPaging();
    const fullName = filters.find((filter) => filter.id === 'fullName')?.query as ListFilterItemValue;
    const email = filters.find((filter) => filter.id === 'email')?.query as ListFilterItemValue;
    const status = filters.find((filter) => filter.id === 'status')?.query as ListFilterItemValue;
    const userRole = filters.find((filter) => filter.id === 'role')?.query as ListFilterItemValue;
    this.search$.next({
      fullName: fullName?.value as string,
      email: email?.value as string,
      status: status?.value as string,
      userRoles: userRole ? [userRole.value as UserRole] : []
    });
  }

  async showMemberEditor(event: Event, id: number): Promise<void> {
    // prevent the row from toggling
    event.stopPropagation();

    const dialog = await this.memberEditorDialog.open({ campaignUserId: id });
    dialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.reload$.next();
      }
    });
  }

  clearQuery(): void {
    this.query.setValue('');
  }
}
