import { MdiMetaService } from '@ih/services';
import { noop } from '@ih/utilities';

const MdiPickerComponent = {
  require: {
    ngModel: 'ngModel'
  },

  template: /* html */ `<md-button md-raised ng-click="$ctrl.showPicker($event)">
  <md-icon ng-bind-html="$ctrl.svg | toTrustedHtml"></md-icon>
</md-button>
`,
  controller: [
    '$mdDialog',
    function ($mdDialog): void {
      const $ctrl = this;
      let $ngModel = null;

      $ctrl.$onInit = function () {
        $ngModel = $ctrl.ngModel;
        $ctrl.ngModel.$render = () => {
          $ctrl.svg = $ctrl.ngModel.$viewValue;
        };
      };

      $ctrl.showPicker = function ($event) {
        $mdDialog
          .show({
            targetEvent: $event,

            template: /* html */ `<md-dialog class="dialog-mdi-picker">
  <md-toolbar>
    <div class="md-toolbar-tools">
      <h2 flex>Icon picker</h2>
      <md-button class="md-icon-button" ng-click="$ctrl.cancel()">
        <md-icon aria-label="Close dialog">
          <svg id="close" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
            />
          </svg>
        </md-icon>
      </md-button>
    </div>
  </md-toolbar>
  <div class="mat-elevation-z1">
    <md-input-container md-no-float>
      <md-icon>
        <svg id="magnify" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
          />
        </svg>
      </md-icon>
      <input
        name="iconQuery"
        md-autofocus
        ng-model="$ctrl.query"
        ng-change="$ctrl.queryChanged()"
        ng-model-options="{ updateOn: 'default blur click', debounce: { 'default': 250, 'blur': 0 } }"
        ng-esc="$ctrl.cancelGroupEdit(group)"
        placeholder="Find icon by name"
        autocomplete="off"
      />
    </md-input-container>
  </div>
  <md-dialog-content flex>
    <div>
      <md-button
        ng-click="$ctrl.select(icon)"
        ng-repeat="icon in $ctrl.filteredIcons | limitTo : 100 as limitedFilteredIcons"
        class="md-icon-button"
        aria-label="{{icon.name}} icon"
      >
        <md-icon md-svg-icon="{{icon.name}}"></md-icon>
        <md-tooltip>{{icon.name}}</md-tooltip>
      </md-button>
    </div>
    <div ng-if="!$ctrl.busy && limitedFilteredIcons.length != 0">
      <h4>Please filter to find more icons...</h4>
    </div>
    <div ng-if="!$ctrl.busy && limitedFilteredIcons.length == 0">
      <h4>Sorry we couldn't find anything to match that</h4>
    </div>
    <div layout="column" layout-align="center center" ng-if="$ctrl.busy">
      <md-progress-circular md-mode="indeterminate"></md-progress-circular>
    </div>
  </md-dialog-content>
</md-dialog>
`,
            controllerAs: '$ctrl',
            controller: [
              '$mdDialog',
              '$mdiMeta',
              '$timeout',
              function ($mdDialog, $mdiMeta: MdiMetaService, $timeout: angular.ITimeoutService): void {
                const $ctrl = this;

                $ctrl.$onInit = () => {
                  $mdiMeta.get$().subscribe((mdiMeta) =>
                    $timeout(() => {
                      $ctrl.mdiMeta = mdiMeta;
                      $ctrl.queryChanged();
                    })
                  );
                };

                $ctrl.queryChanged = function () {
                  const query = $ctrl.query?.toLowerCase() || '';
                  $ctrl.filteredIcons = $ctrl.mdiMeta.filter((item) => {
                    return (
                      item.aliases.some(function (alias) {
                        return alias.toLowerCase().indexOf(query.toLowerCase()) !== -1;
                      }) || item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
                    );
                  });
                };

                $ctrl.select = function (icon) {
                  $mdiMeta.getSvgByName$(icon.name).subscribe((svg) => {
                    $mdDialog.hide(svg);
                  });
                };

                $ctrl.cancel = $mdDialog.cancel;
              }
            ],
            bindToController: true,
            parent: window.document.body
          })
          .then(function (result) {
            $ngModel.$setViewValue(result);
            $ctrl.svg = result;
          })
          .catch(noop);
      };
      $ctrl.getIcon = function (icon) {
        if (/mdi-/.test(icon)) return icon.substring(4);
        return icon;
      };
    }
  ]
};

export default MdiPickerComponent;
