import { LazySnackBarService } from '@ih/services';

const ResponseListComponent = {
  template: /* html */ `<div class="response-list">
  <div class="page-header" layout="row">
    <span flex>Responses</span>
    <md-button class="md-raised" router-link="/responses/new">Add response</md-button>
  </div>
  <div class="page-body" md-whiteframe="4" layout="column">
    <md-toolbar class="response-header md-accent" layout="row" layout-align="start center">
      <md-input-container flex>
        <input
          id="txtFind"
          type="text"
          ng-model="$ctrl.query"
          name="x"
          placeholder="Find responses by trigger..."
          ng-change="$ctrl.loadResponses($ctrl.query);"
          ng-model-options="{updateOn: 'default blur',debounce: { 'default': 500, 'blur': 0 }}"
        />
      </md-input-container>
      <md-button class="md-icon-button" ng-click="$ctrl.clearQuery()">
        <md-icon>
          <svg id="close" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
            />
          </svg>
        </md-icon>
      </md-button>
      <md-button class="md-icon-button" ng-click="$ctrl.queryChanged()">
        <md-icon>
          <svg id="refresh" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M17.65,6.35C16.2,4.9 14.21,4 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20C15.73,20 18.84,17.45 19.73,14H17.65C16.83,16.33 14.61,18 12,18A6,6 0 0,1 6,12A6,6 0 0,1 12,6C13.66,6 15.14,6.69 16.22,7.78L13,11H20V4L17.65,6.35Z"
            />
          </svg>
        </md-icon>
      </md-button>
      <md-menu md-position-mode="target-right target">
        <md-button aria-label="Open settings menu" class="md-icon-button" ng-click="$mdMenu.open($event)">
          <md-icon>
            <svg id="dots-vertical" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z"
              />
            </svg>
          </md-icon>
        </md-button>
        <md-menu-content>
          <md-menu-item>
            <md-button ng-click="$ctrl.changeSort('popular')">
              <div layout="row" flex>

                <md-icon md-menu-align-target>
                  <svg id="poll" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M3,22V8H7V22H3M10,22V2H14V22H10M17,22V14H21V22H17Z" />
                  </svg>
                </md-icon>
                <p flex>Popular</p>
              </div>
            </md-button>
          </md-menu-item>
          <md-menu-item>
            <md-button ng-click="$ctrl.changeSort('recentlyUsed')">
              <div layout="row" flex>

                <md-icon md-menu-align-target>
                  <svg id="history" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M13.5,8H12V13L16.28,15.54L17,14.33L13.5,12.25V8M13,3A9,9 0 0,0 4,12H1L4.96,16.03L9,12H6A7,7 0 0,1 13,5A7,7 0 0,1 20,12A7,7 0 0,1 13,19C11.07,19 9.32,18.21 8.06,16.94L6.64,18.36C8.27,20 10.5,21 13,21A9,9 0 0,0 22,12A9,9 0 0,0 13,3"
                    />
                  </svg>
                </md-icon>
                <p flex>Last used</p>
              </div>
            </md-button>
          </md-menu-item>
          <md-menu-item>
            <md-button ng-click="$ctrl.changeSort('recentlyAdded')">
              <div layout="row" flex>

                <md-icon md-menu-align-target>
                  <svg id="new-box" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M20,4C21.11,4 22,4.89 22,6V18C22,19.11 21.11,20 20,20H4C2.89,20 2,19.11 2,18V6C2,4.89 2.89,4 4,4H20M8.5,15V9H7.25V12.5L4.75,9H3.5V15H4.75V11.5L7.3,15H8.5M13.5,10.26V9H9.5V15H13.5V13.75H11V12.64H13.5V11.38H11V10.26H13.5M20.5,14V9H19.25V13.5H18.13V10H16.88V13.5H15.75V9H14.5V14A1,1 0 0,0 15.5,15H19.5A1,1 0 0,0 20.5,14Z"
                    />
                  </svg>
                </md-icon>
                <p flex>New</p>
              </div>
            </md-button>
          </md-menu-item>
        </md-menu-content>
      </md-menu>
    </md-toolbar>

    <md-list
      infinite-scroll="$ctrl.loadResponses()"
      infinite-scroll-distance="3"
      infinite-scroll-disabled="$ctrl.busy || $ctrl.noMore"
      ih-intecept-href
    >
      <md-list-item ng-repeat="item in $ctrl.responses" href="/responses/{{item.slackResponseId}}">
        <div class="lv-item media" layout="row" flex>
          <div class="media-body" flex>
            <div class="lv-title">Triggers</div>
            <small
              class="lv-small"
              ng-repeat="trigger in item.trigger.split(',') | limitTo : item.triggerLimit as triggerResults "
              >{{trigger}}</small
            >
            <a
              href=""
              ng-if="item.triggerLimit < item.trigger.split(',').length"
              ng-click="item.triggerLimit = item.trigger.split(',').length"
              >More</a
            >
            <div class="lv-title">Responses</div>
            <small
              class="lv-small"
              ng-repeat="response in item.response.split('\n') | limitTo : item.responseLimit as responseResults"
              >{{response}}</small
            >
            <a
              href=""
              ng-if="item.responseLimit < item.response.split('\n').length"
              ng-click="item.responseLimit = item.response.split('\n').length"
              >More</a
            >
            <ul class="lv-attrs">
              <li>Created: {{item.createdAt|date}} by {{item.createdBy}}</li>
              <li>
                Last Updated: {{(item.updatedAt|date) || 'never'}}<span ng-if="item.updatedAt">
                  by {{item.updatedBy}}
                </span>
              </li>
              <li>Use Count: {{item.useCount}}</li>
              <li>Last Used: {{(item.lastUse|date) || 'never'}}</li>
            </ul>
          </div>
          <md-button class="md-icon-button" ng-click="$ctrl.deleteResponse(item)">
            <md-icon>
              <svg id="delete" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z"
                />
              </svg>
            </md-icon>
          </md-button>
        </div>
      </md-list-item>
    </md-list>
  </div>
</div>
`,
  controller: [
    '$http',
    '$q',
    '$lazySnackbar',
    function ($http: angular.IHttpService, $q: angular.IQService, $lazySnackbar: LazySnackBarService): void {
      const $ctrl = this;

      $ctrl.noMore = false;
      $ctrl.itemCounter = 0;
      $ctrl.sort = 'popular';

      $ctrl.changeSort = function (sort) {
        $ctrl.sort = sort;
        $ctrl.loadResponses($ctrl.query || '');
      };

      let canceler;
      $ctrl.loadResponses = function (queryOverride) {
        if (queryOverride != null) {
          $ctrl.query = queryOverride;
          $ctrl.noMore = false;
          $ctrl.itemCounter = 0;
          $ctrl.responses = null;
        }

        if ($ctrl.busy || $ctrl.noMore) return;

        if (canceler) {
          canceler.resolve();
        }

        canceler = $q.defer();

        $ctrl.busy = true;
        $http
          .get<any>('/api/responses', {
            params: {
              q: $ctrl.query,
              skip: $ctrl.itemCounter,
              limit: 50,
              sort: $ctrl.sort
            },
            timeout: canceler.promise
          })
          .then(
            function (resp) {
              $ctrl.busy = false;
              const data = resp.data;
              if (data.length === 0 || data.length < 10) $ctrl.noMore = true;
              $ctrl.itemCounter += data.length;
              $ctrl.responses = ($ctrl.responses || []).concat(data);
            },
            function (resp) {
              switch (resp.xhrStatus) {
                case 'abort':
                  // request cancelled, ignore
                  break;
                default:
                  $lazySnackbar.open('Unable to load responses');
                  break;
              }
            }
          );
      };

      $ctrl.deleteResponse = function (response) {
        $http.delete('/api/responses/' + response.slackResponseId).then(
          function (resp) {
            $ctrl.responses.splice($ctrl.responses.indexOf(response), 1);
          },
          function (resp) {
            alert('could not delete image');
          }
        );
      };
    }
  ]
};

export default ResponseListComponent;
