import { Router } from '@angular/router';

const appController = [
  '$http',
  '$router',
  '$mdSidenav',
  function appController($http: angular.IHttpService, $router: Router, $mdSidenav): void {
    const $ctrl = this;

    $ctrl.currentUser = window.currentUser;
    $ctrl.config = window.campaign;

    $http.get('/api/account/checkLoggedIn').then(function (resp) {
      // success
      $ctrl.currentUser = resp.data;
    });

    $ctrl.toggleLeft = function () {
      $mdSidenav('left').toggle();
    };

    $ctrl.updateNow = function () {
      window.location.reload();
    };

    $ctrl.navigateTo = function (path) {
      $router.navigate([path]);
    };

    // collapse sidebar when link clicked
    $(".sidebar-nav a[href!=''][href]").click(function () {
      $('#wrapper').removeClass('toggled');
    });
  }
];

export default appController;
