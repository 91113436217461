<section class="member-list">
  <header class="member-list-header bridge-header sticky-header">
    <div class="fx-layout-row fx-flex">
      <div class="pull-left fx-layout-align-start-center fx-flex">
        <mat-icon class="member-list-icon">
          <svg viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z"
            />
          </svg>
        </mat-icon>
        <div class="member-list-title">Members</div>
      </div>
    </div>
  </header>

  <div class="member-list-container fx-layout-column">
    <ih-filter-chips
      class="member-list-filter-chips"
      [campaignId]="campaignId"
      ihMemberFilterChips
      (filterChanged)="filterChanged($event)"
    ></ih-filter-chips>
    <mat-divider></mat-divider>

    <div class="loading-shade" *ngIf="isLoadingResults">
      <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div>

    <mat-accordion class="member-list-accordion">
      <mat-expansion-panel hideToggle class="user-list-user-item" *ngFor="let user of users$ | async">
        <mat-expansion-panel-header class="fx-layout-row fx-layout-align-start-center">
          <mat-panel-title class="fx-layout-row fx-layout-align-start-center">
            <div class="user-img-container fx-layout-column">
              <ih-image class="user-img" [imageInfo]="user.image"></ih-image>
            </div>
            <div class="user-detail-row fx-layout-column">
              <div class="user-fullname">
                {{ user.fullName || 'Name unavailable' }}
              </div>

              <div class="user-email">
                {{ user.email || 'Email unavailable' }}
              </div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <!-- Member Info -->
        <div class="member-list-member-detail fx-layout-column fx-layout-align-center-start">
          <div class="user-hub-role fx-layout-row fx-layout-align-start-center">
            <b>User hub role:</b>&nbsp;{{ user.role }}
          </div>

          <div class="user-email-status fx-layout-row fx-layout-align-start-center">
            <div class="user-email-status-title"><b>Email:</b>&nbsp;</div>
            <div class="user-status-verified-label" *ngIf="user.verified">Verified</div>
            <div class="user-status-unverified-label" *ngIf="!user.verified">Unverified</div>
          </div>

          <div class="user-status fx-layout-row fx-layout-align-start-center">
            <div class="user-status-title"><b>Status:</b>&nbsp;</div>
            <div class="user-status-active-label" *ngIf="user.status === 'Active'">
              {{ user.status }}
            </div>
            <div class="user-status-inactive-label" *ngIf="user.status === 'Inactive'">
              {{ user.status }}
            </div>
            <div class="user-status-invited-label" *ngIf="user.status === 'Invited'">
              {{ user.status }}
            </div>
            <div class="user-status-suspended-label" *ngIf="user.status === 'Suspended'">
              {{ user.status }}
            </div>
          </div>

          <div class="user-last-active fx-layout-row fx-layout-align-start-center">
            <b>Last active:</b>&nbsp;{{ (user.lastActive | date : 'longDate') || 'Never' }}
          </div>
        </div>

        <mat-action-row class="user-controls">
          <button
            class="user-edit-btn"
            mat-button
            [disabled]="true"
            (click)="showMemberEditor($event, user.campaignUserId)"
          >
            Edit
          </button>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-divider [hidden]="resultsLength === 0 && !isLoadingResults"></mat-divider>
    <mat-paginator
      class="member-list-paginator"
      [hidden]="resultsLength === 0 && !isLoadingResults"
      [pageSizeOptions]="[100, 250, 500]"
      [length]="resultsLength"
      [pageSize]="100"
      (page)="changePage()"
    >
    </mat-paginator>

    <div class="no-members-match-container" *ngIf="resultsLength === 0 && !isLoadingResults">
      <div class="no-members-match">
        <span class="no-members-match-label">No members match your current selection</span>
      </div>
    </div>
  </div>
</section>
