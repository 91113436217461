import assign from 'lodash-es/assign';
CampaignSlugInUseDirective.$inject = ['$http', '$q', '$timeout'];
export default function CampaignSlugInUseDirective($http, $q, $timeout) {
  return {
    require: 'ngModel',
    link: function (scope, elem, attrs, ctrl) {
      let pendingValidation;
      let canceler;
      ctrl.$asyncValidators.nameInUse = function (modelValue) {
        const originalName = (scope.$eval(attrs.originalName) || '').trim();
        if (ctrl.$isEmpty(modelValue) || modelValue.toLowerCase() === originalName.toLowerCase()) {
          return $q.resolve();
        }

        const opts = assign(
          {
            debounce: 400
          },
          scope.$eval(attrs.campaignSlugInUse)
        );

        modelValue = modelValue.toLowerCase().trim();

        if (pendingValidation) $timeout.cancel(pendingValidation);
        if (canceler) canceler.resolve();
        canceler = $q.defer();

        pendingValidation = $timeout(() => {
          pendingValidation = null;

          return $http
            .get('/api/campaigns/slug/valid', {
              params: { slug: modelValue },
              timeout: canceler.promise
            })
            .then(function (resp) {
              const data = resp.data;
              if (!data) {
                throw new Error('slug in use');
              } else if (!elem.is(':focus')) {
                $timeout(function () {
                  scope.$eval(attrs.campaignSlugInUse);
                });
              }
            });
        }, opts.debounce);

        return pendingValidation;
      };
    }
  };
}
