import { ConfirmDialogService } from '@ih/confirm';
import { ChannelJoinType } from '@ih/enums';
import { LazySnackBarService } from '@ih/services';
import { getChannelMdiIconSvg, wrapSvg } from '@ih/utilities';
import { mdiCancel, mdiChevronDown, mdiChevronUp, mdiUndo } from '@mdi/js';
import merge from 'lodash-es/merge';
import remove from 'lodash-es/remove';
import { SideNavService } from '../../side-nav.service';
import ChannelOwnerTransferDialogService from '../channelOwnerTransferDialog/channelOwnerTransferDialog.service';

const ChannelListComponent = {
  bindings: {
    campaignId: '<',
    onShowSmsLog: '&'
  },

  template: /* html */ `<section class="app-channels">
  <header class="app-channels-header bridge-header sticky-header" layout="row" layout-align="start center">
    <div class="pull-left" layout="row" layout-align="start center" flex>
    <md-icon class="app-channel-icon">
      <svg viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M5.41,21L6.12,17H2.12L2.47,15H6.47L7.53,9H3.53L3.88,7H7.88L8.59,3H10.59L9.88,7H15.88L16.59,3H18.59L17.88,7H21.88L21.53,9H17.53L16.47,15H20.47L20.12,17H16.12L15.41,21H13.41L14.12,17H8.12L7.41,21H5.41M9.53,9L8.47,15H14.47L15.53,9H9.53Z"
        />
      </svg>
    </md-icon>
      <div class="app-channels-title">Channels</div>
      <span flex></span>
      <div layout="row" layout-align="end" class="button-controls">
        <md-input-container>
          <md-select
            ng-model="$ctrl.channelsSelect"
            ng-change="$ctrl.getMoreChannels($ctrl.channelsSelect)"
            placeholder="Channel type"
          >
            <md-option value="all"> All </md-option>
            <md-option value="public"> Public </md-option>
            <md-option value="request"> Request </md-option>
            <md-option value="invite-only"> Private </md-option>
            <md-option value="suspended"> Suspended </md-option>
          </md-select>
        </md-input-container>
      </div>
    </div>
  </header>

  <div class="app-channels-container">
    <div class="page-body app-channels-page-body" layout="column">
      <!-- Channel Search -->
      <div class="app-channels-search-row" layout="row">
        <md-input-container class="md-icon-right" layout="row" flex>
          <input
            id="txtFind"
            type="text"
            ng-model="$ctrl.query"
            name="x"
            placeholder="Find channels by name..."
            ng-change="$ctrl.getMoreChannels($ctrl.type);"
            ng-model-options="{updateOn: 'default blur',debounce: { 'default': 500, 'blur': 0 }}"
          />
          <md-icon ng-click="$ctrl.clearQuery()">
            <svg viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
              />
            </svg>
          </md-icon>
        </md-input-container>
      </div>

      <md-divider class="app-channels-md-divider"></md-divider>

      <md-list
        infinite-scroll="$ctrl.getMoreChannels()"
        infinite-scroll-immediate-check="false"
        infinite-scroll-disabled="$ctrl.busy || $ctrl.noMore"
        infinite-scroll-distance="3"
      >
        <md-list-item class="channel-list-item">
          <span flex="5">&nbsp;</span>
          <a href="" ng-click="$ctrl.changePredicate('name')" flex="40">
            Channel Name
            <md-icon
              ng-bind-html="$ctrl.predicate.icon | toTrustedHtml"
              ng-if="$ctrl.predicate.field=='name'"
            ></md-icon>
          </a>
          <a href="" ng-click="$ctrl.changePredicate('owner')" flex="15">
            Owner
            <md-icon
              ng-bind-html="$ctrl.predicate.icon | toTrustedHtml"
              ng-if="$ctrl.predicate.field=='owner'"
            ></md-icon>
          </a>
          <a href="" ng-click="$ctrl.changePredicate('memberCount')" class="badge-heading text-right" flex="10">
            Members
            <md-icon
              ng-bind-html="$ctrl.predicate.icon | toTrustedHtml"
              ng-if="$ctrl.predicate.field=='memberCount'"
            ></md-icon>
          </a>
          <a href="" ng-click="$ctrl.changePredicate('smsUserCount')" class="badge-heading text-right" flex="10">
            SMS Users
            <md-icon
              ng-bind-html="$ctrl.predicate.icon | toTrustedHtml"
              ng-if="$ctrl.predicate.field=='smsUserCount'"
            ></md-icon>
          </a>
          <a href="" ng-click="$ctrl.changePredicate('smsCount')" class="badge-heading text-right" flex="10">
            SMS this month
            <md-icon
              ng-bind-html="$ctrl.predicate.icon | toTrustedHtml"
              ng-if="$ctrl.predicate.field=='smsCount'"
            ></md-icon>
          </a>
          <a href="" ng-click="$ctrl.changePredicate('status')" class="badge-heading text-left" flex="10">
            SMS Status
            <md-icon
              ng-bind-html="$ctrl.predicate.icon | toTrustedHtml"
              ng-if="$ctrl.predicate.field=='status'"
            ></md-icon>
          </a>
          <div flex="5">&nbsp;</div>
        </md-list-item>
        <md-list-item class="channel-list-item" ng-repeat="channel in $ctrl.campaign.channels">
          <span flex="5">
            <md-icon ng-bind-html="$ctrl.getChannelIcon(channel) | toTrustedHtml"></md-icon>
          </span>
          <span flex="40">{{::channel.name}}</span>
          <span flex="15" class="channel-owner hidden-xs" title="{{channel.owner.email}}"
            >{{channel.owner.fullName}}</span
          >
          <span flex="10" class="text-right text-bold" title="Number of users in the channel">
            {{channel.userCount}}<span ng-if="channel.pendingCount>0" title="Pending users">
              ({{channel.pendingCount }})
            </span>
          </span>
          <span
            flex="10"
            title="Number of users enabled sms in the channel"
            class="sms-disabled-wrapper text-right text-bold"
            >{{channel.smsUserCount || 0}}</span
          >
          <span flex="10" class="text-right text-bold" title="Number of sms sent in the channel"
            >{{channel.smsCount || 0}}</span
          >
          <span flex="10" title="SMS status">
            <span class="badge-status" ng-class="{active:channel.smsEnabled}"
              >{{channel.smsEnabled?'Active':'Disabled'}}</span
            >
          </span>
          <md-menu flex="5" md-position-mode="target-right target">
            <md-button aria-label="Open settings menu" class="md-icon-button" ng-click="$mdMenu.open($event)">
              <md-icon md-menu-origin md-svg-icon="dots-vertical"></md-icon>
            </md-button>
            <md-menu-content width="4">
              <md-menu-item>
                <md-button ng-click="$ctrl.showChangeChannelOwner($ctrl.campaign, channel, $event)">
                  <div layout="row" flex>
                    <p flex>Change owner</p>
                    <md-icon
                      md-menu-align-target
                      md-svg-icon="transit-transfer"
                      style="margin: auto 3px auto 0"
                    ></md-icon>
                  </div>
                </md-button>
              </md-menu-item>
              <md-menu-item ng-if="!channel.everyone">
                <md-button ng-click="$ctrl.toggleSuspend(channel, $event)">
                  <div layout="row" flex>
                    <p flex>{{channel.suspended?'Restore':'Suspend'}}</p>
                    <md-icon
                      md-menu-align-target
                      md-svg-icon="{{channel.suspended?'undo':'cancel'}}"
                      style="margin: auto 3px auto 0"
                    ></md-icon>
                  </div>
                </md-button>
              </md-menu-item>
              <md-menu-item ng-if="channel.isPrivate && !channel.everyone">
                <md-button ng-click="$ctrl.changePrivacy(channel, $ctrl.channelType.Public)">
                  <div layout="row" flex>
                    <p flex>Make public</p>
                    <md-icon md-menu-align-target md-svg-icon="earth" style="margin: auto 3px auto 0"></md-icon>
                  </div>
                </md-button>
              </md-menu-item>
              <md-menu-item ng-if="(channel.type == $ctrl.channelType.Public || channel.type == $ctrl.ChannelJoinType.Request) && !channel.everyone">
                <md-button ng-click="$ctrl.changePrivacy(channel, $ctrl.ChannelJoinType.Closed)">
                  <div layout="row" flex>
                    <p flex>Make private</p>
                    <md-icon md-menu-align-target md-svg-icon="lock" style="margin: auto 3px auto 0"></md-icon>
                  </div>
                </md-button>
              </md-menu-item>
              <md-menu-item ng-if="(channel.type == $ctrl.channelType.Public || channel.type == $ctrl.ChannelJoinType.Closed) && !channel.everyone">
                <md-button ng-click="$ctrl.changePrivacy(channel, $ctrl.ChannelJoinType.Request)">
                  <div layout="row" flex>
                    <p flex>Make request to join</p>
                    <md-icon md-menu-align-target md-svg-icon="account-key" style="margin: auto 3px auto 0"></md-icon>
                  </div>
                </md-button>
              </md-menu-item>
              <md-menu-item>
                <md-button ng-click="$ctrl.toggleSMS(channel, $event)">
                  <div layout="row" flex>
                    <p flex>{{channel.smsEnabled?'Disable':'Enable'}} SMS</p>
                    <md-icon md-menu-align-target md-svg-icon="message-text" style="margin: auto 3px auto 0"></md-icon>
                  </div>
                </md-button>
              </md-menu-item>
              <md-menu-item>
                <md-button router-link="{{'/apps/' + $ctrl.campaignId + '/sms'}}">
                  <div layout="row" flex>
                    <p flex>View SMS Log</p>
                    <md-icon md-menu-align-target md-svg-icon="file-document" style="margin: auto 3px auto 0"></md-icon>
                  </div>
                </md-button>
              </md-menu-item>
            </md-menu-content>
          </md-menu>
        </md-list-item>
        <md-list-item ng-if="$ctrl.campaign.channels.length == 0 && $ctrl.busy">
          <div>Loading channels</div>
        </md-list-item>
        <md-list-item ng-if="$ctrl.campaign.channels.length == 0 && !$ctrl.busy">
          <div>No matches found</div>
        </md-list-item>
      </md-list>
    </div>
  </div>
</section>
`,
  controller: [
    '$http',
    '$q',
    '$lazySnackbar',
    '$channelOwnerTransferDialog',
    '$confirmDialog',
    '$timeout',
    '$sideNavService',
    function (
      $http: angular.IHttpService,
      $q: angular.IQService,
      $lazySnackbar: LazySnackBarService,
      $channelOwnerTransferDialog: ChannelOwnerTransferDialogService,
      $confirmDialog: ConfirmDialogService,
      $timeout: angular.ITimeoutService,
      $sideNavService: SideNavService
    ) {
      const $ctrl = this;

      $ctrl.channelType = ChannelJoinType;

      $ctrl.channelsSelect = 'all';

      $ctrl.$onInit = () => {
        $sideNavService.setCurrentApp(this.campaignId);
        $http.get('/api/campaigns/' + $ctrl.campaignId).then((resp) => {
          $ctrl.campaign = resp.data;
          $ctrl.clearQuery();
        });
      };

      $ctrl.mdiUndo = wrapSvg(mdiUndo);
      $ctrl.mdiCancel = wrapSvg(mdiCancel);

      $ctrl.predicate = {
        field: 'name',
        icon: wrapSvg(mdiChevronUp)
      };

      $ctrl.changePredicate = function (predicate) {
        if ($ctrl.predicate.field === predicate) {
          $ctrl.predicate.icon =
            $ctrl.predicate.icon === wrapSvg(mdiChevronUp) ? wrapSvg(mdiChevronDown) : wrapSvg(mdiChevronUp);
        } else {
          $ctrl.predicate = {
            field: predicate,
            icon: wrapSvg(mdiChevronUp)
          };
        }
        $ctrl.resetInfiniteScroll();
        $ctrl.getMoreChannels();
      };

      $ctrl.clearQuery = function () {
        $ctrl.query = null;
        $ctrl.resetInfiniteScroll();
        $ctrl.getMoreChannels();
      };

      $ctrl.queryChanged = function () {
        $ctrl.resetInfiniteScroll();
        $ctrl.getMoreChannels();
      };

      $ctrl.resetInfiniteScroll = function () {
        $ctrl.itemCounter = 0;
        $ctrl.noMore = false;
        $ctrl.busy = false;
        $ctrl.campaign.channels = [];
      };

      let canceler;
      let opts = {};
      $ctrl.getMoreChannels = function (type) {
        if (!$ctrl.campaign) return;

        if (canceler) {
          canceler.resolve();
        }

        if (type || !opts) {
          $ctrl.type = type || 'all';

          $ctrl.resetInfiniteScroll();

          switch ($ctrl.type) {
            case 'all':
              opts = {};
              break;
            case 'public':
              opts = { private: false };
              break;
            case 'request':
              opts = { request: true };
              break;
            case 'invite-only':
              opts = { private: true, request: false };
              break;
            case 'suspended':
              opts = { suspend: true, includeRequest: true };
              break;
            default:
              throw new Error(`unsupported type specified :${type}`);
          }
        }

        if (($ctrl.busy || $ctrl.noMore) && !type) return;

        canceler = $q.defer();

        $ctrl.busy = true;
        // get user list
        $http
          .get<any>('/api/campaigns/' + $ctrl.campaign.campaign_id + '/channels', {
            params: merge(
              {
                q: $ctrl.query,
                skip: $ctrl.itemCounter,
                limit: 50,
                suspend: false,
                sort: $ctrl.predicate.field,
                ascending: $ctrl.predicate.icon === mdiChevronUp
              },
              opts
            ),
            timeout: canceler.promise
          })
          .then(function (resp) {
            const data = resp.data;
            if (data.remaining === 0) $ctrl.noMore = true;
            $ctrl.itemCounter += data.items.length;
            $ctrl.campaign.channels = $ctrl.campaign.channels.concat(data.items);
            $ctrl.busy = false;
          });
      };

      $ctrl.showChangeChannelOwner = function (campaign, channel, ev) {
        $channelOwnerTransferDialog
          .show(ev, {
            campaign,
            channel
          })
          .then(function (member) {
            $http
              .put(
                '/api/campaigns/' + $ctrl.campaignId + '/channels/' + channel.channelId + '/owner/' + member.authorId,
                {}
              )
              .then(
                function (resp) {
                  channel.owner = member;
                  $lazySnackbar.open('Ownership has been transferred to ' + member.firstName);
                },
                function (resp) {
                  $lazySnackbar.open('There was a problem transferring ownership');
                }
              );
          })
          .catch(function () {
            // cancelled
          });
      };

      $ctrl.toggleSuspend = async function (channel) {
        if (!channel.suspended) {
          const dialog = await $confirmDialog.open({
            title: `Are you sure you want to SUSPEND ${channel.name}?`,
            confirmText: 'SUSPEND CHANNEL'
          });
          dialog.afterClosed().subscribe((result) => {
            $timeout(() => {
              if (result) {
                $http
                  .put('/api/campaigns/' + $ctrl.campaignId + '/channels/' + channel.channelId + '/suspend', {})
                  .then(function () {
                    $lazySnackbar.open(channel.name + ' has been suspended.');
                    // remove the channel from the list
                    remove($ctrl.campaign.channels, channel);
                  });
              }
            });
          });
        } else {
          const dialog = await $confirmDialog.open({
            title: `Are you sure you want to RESTORE ${channel.name}?`,
            confirmText: 'RESTORE CHANNEL'
          });
          dialog.afterClosed().subscribe((result) => {
            $timeout(() => {
              if (result) {
                $http
                  .put('/api/campaigns/' + $ctrl.campaignId + '/channels/' + channel.channelId + '/suspend', {})
                  .then(function () {
                    $lazySnackbar.open('It was tough, we almost gave up on it, but we did it.');
                    // remove the channel from the list
                    remove($ctrl.campaign.channels, channel);
                  });
              }
            });
          });
        }
      };

      $ctrl.changePrivacy = (channel, type: ChannelJoinType) => {
        $http.post('/api/channels/' + channel.channelId + '/type', type).then(function () {
          channel.type = type;

          $lazySnackbar.open(channel.name + ' type has been updated');
        });
      };

      $ctrl.toggleSMS = function (channel) {
        $http
          .put('/api/campaigns/' + $ctrl.campaignId + '/channels/' + channel.channelId + '/sms', {})
          .then(function () {
            channel.smsEnabled = !channel.smsEnabled;

            $lazySnackbar.open(channel.name + ' SMS setting has been updated');
          });
      };

      $ctrl.getChannelIcon = getChannelMdiIconSvg;
    }
  ]
};

export default ChannelListComponent;
