import { ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { SaveChangesDialogService } from '@ih/dialogs';
import { ImageComponent } from '@ih/image';
import { characterTransform } from '@ih/pipes';
import {
  EmojiService,
  FirebaseService,
  LazySnackBarService,
  MdiMetaService,
  ScriptService,
  StorageService,
  TributeService
} from '@ih/services';
import 'angular';
import 'angular-animate';
import 'angular-aria';
import 'angular-material';
import 'angular-material-data-table';
import 'angular-messages';
import 'angular-route';
import 'angular-sanitize';

import { ConfirmDialogService } from '@ih/confirm';
import { FroalaComponent } from '@ih/froala';
import MdiPickerComponent from 'ihub-shared/ng1/components/mdi-picker/mdi-picker';
import RouterLinkDirective from 'ihub-shared/ng1/directives/router-link.directive';
import {
  AppUrlFilter,
  EmojiFilter,
  FromNowFilter,
  HtmlToPlainTextFilter,
  PhoneFilter,
  ToSrcSetFilter,
  ToTrustedHtmlFilter
} from 'ihub-shared/ng1/filters';
import InfiniteScrollDirective from 'ihub-shared/ng1/ng-infinite-scroll';
import { CreateAppDialogService } from '../create-app-dialog/create-app-dialog.service';
import { MemberListComponent } from '../member-list/member-list.component';
import { SideNavService } from '../side-nav.service';
import appConfig from './app.config.ng1';
import appController from './app.controller.ng1';
import appRun from './app.run.ng1';
import AppListComponent from './appList/appList';
import CampaignSlugInUseDirective from './campaignSlugInUse.directive';
import ChannelListComponent from './channelList/channelList';
import ChannelOwnerTransferDialogService from './channelOwnerTransferDialog/channelOwnerTransferDialog.service';
import ChannelPickerDirective from './channelPicker/channelPicker';
import ReactionDetailComponent from './reactionDetail/reactionDetail';
import ReactionListComponent from './reactionList/reactionList';
import ResponseDetailComponent from './responseDetail/responseDetail';
import ResponseListComponent from './responseList/responselist';
import SmsLogComponent from './smsLog/smsLog';
import UpdateDetailComponent from './updateDetail/updateDetail';
import UpdateListComponent from './updateList/updateList';
import UserDetailComponent from './userDetail/userDetail';

declare const angular: angular.IAngularStatic;
export const NG1_MODULE_NAME = 'bridge';

const moduleDependencies = ['ngRoute', 'ngSanitize', 'ngAnimate', 'ngMaterial', 'ngMessages'];

const idbConfig = {
  idbStore: NG1_MODULE_NAME,
  idbDatabase: 'data'
};

angular
  .module(NG1_MODULE_NAME, moduleDependencies)
  .value('THROTTLE_MILLISECONDS', null)
  .constant('config', idbConfig)
  .config(appConfig)
  .run(appRun)
  .controller('ApplicationController', appController)

  .directive('channelPicker', ChannelPickerDirective)
  .directive('campaignSlugInUse', CampaignSlugInUseDirective)
  .directive('routerLink', RouterLinkDirective)
  .directive('ihFroala', downgradeComponent({ component: FroalaComponent }))
  .directive('ihImage', downgradeComponent({ component: ImageComponent }))
  .directive('ihMemberList', downgradeComponent({ component: MemberListComponent }))
  .directive('infiniteScroll', InfiniteScrollDirective)

  .component('appList', AppListComponent)
  .component('channelList', ChannelListComponent)
  .component('reactionDetail', ReactionDetailComponent)
  .component('reactionList', ReactionListComponent)
  .component('responseDetail', ResponseDetailComponent)
  .component('responseList', ResponseListComponent)
  .component('smsLog', SmsLogComponent)
  .component('updateDetail', UpdateDetailComponent)
  .component('updateList', UpdateListComponent)
  .component('userDetail', UserDetailComponent)
  .component('mdiPicker', MdiPickerComponent)

  .service('$channelOwnerTransferDialog', ChannelOwnerTransferDialogService)
  .factory('$emoji', downgradeInjectable(EmojiService))
  .factory('$storage', downgradeInjectable(StorageService))
  .factory('$firebase', downgradeInjectable(FirebaseService))
  .factory('$tribute', downgradeInjectable(TributeService))
  .factory('$confirmDialog', downgradeInjectable(ConfirmDialogService))
  .factory('$lazySnackbar', downgradeInjectable(LazySnackBarService))
  .factory('$appRef', downgradeInjectable(ApplicationRef))
  .factory('$mdiMeta', downgradeInjectable(MdiMetaService))
  .factory('$script', downgradeInjectable(ScriptService))
  .factory('$saveChangesDialog', downgradeInjectable(SaveChangesDialogService))
  .factory('$sideNavService', downgradeInjectable(SideNavService))
  .factory('$createApp', downgradeInjectable(CreateAppDialogService))

  .factory('$router', downgradeInjectable(Router))

  .filter('appUrl', AppUrlFilter)
  .filter('fromNow', FromNowFilter)
  .filter('htmlToPlainText', HtmlToPlainTextFilter)
  .filter('toSrcSet', ToSrcSetFilter)
  .filter('toTrustedHtml', ToTrustedHtmlFilter)
  .filter('characters', () => characterTransform)
  .filter('emoji', EmojiFilter)
  .filter('phone', PhoneFilter);
