import { LazySnackBarService } from '@ih/services';

const ReactionListComponent = {
  template: /* html */ `<div class="reaction-list">
  <div class="page-header" layout="row">
    <span flex>Reactions</span>
    <md-button class="md-raised" router-link="/reactions/new">Add reaction</md-button>
  </div>
  <div class="page-body" md-whiteframe="4" layout="column">
    <md-toolbar class="md-accent" layout="column">
      <div class="flex app-tab-padding" layout="row">
        <md-tabs class="md-accent" md-selected flex>

        <md-tab md-on-select="$ctrl.loadReactions('all')">
          <md-tab-label>All</md-tab-label>
        </md-tab>

        <md-tab md-on-select="$ctrl.loadReactions('mine')">
          <md-tab-label>Mine</md-tab-label>
        </md-tab>
        </md-tabs>

        <md-menu md-position-mode="target-right target">
          <md-button aria-label="Open settings menu" class="md-icon-button" ng-click="$mdMenu.open($event)">
            <md-icon>
              <svg id="dots-vertical" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z"
                />
              </svg>
            </md-icon>
          </md-button>
          <md-menu-content>
            <md-menu-item>
              <md-button ng-click="$ctrl.changeSort('popular')">
                <div layout="row" flex>

                  <md-icon md-menu-align-target>
                    <svg id="poll" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M3,22V8H7V22H3M10,22V2H14V22H10M17,22V14H21V22H17Z" />
                    </svg>
                  </md-icon>
                  <p flex>Popular</p>
                </div>
              </md-button>
            </md-menu-item>
            <md-menu-item>
              <md-button ng-click="$ctrl.changeSort('recentlyUsed')">
                <div layout="row" flex>

                  <md-icon md-menu-align-target>
                    <svg id="history" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M13.5,8H12V13L16.28,15.54L17,14.33L13.5,12.25V8M13,3A9,9 0 0,0 4,12H1L4.96,16.03L9,12H6A7,7 0 0,1 13,5A7,7 0 0,1 20,12A7,7 0 0,1 13,19C11.07,19 9.32,18.21 8.06,16.94L6.64,18.36C8.27,20 10.5,21 13,21A9,9 0 0,0 22,12A9,9 0 0,0 13,3"
                      />
                    </svg>
                  </md-icon>
                  <p flex>Last used</p>
                </div>
              </md-button>
            </md-menu-item>
            <md-menu-item>
              <md-button ng-click="$ctrl.changeSort('recentlyAdded')">
                <div layout="row" flex>

                  <md-icon md-menu-align-target>
                    <svg id="new-box" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M20,4C21.11,4 22,4.89 22,6V18C22,19.11 21.11,20 20,20H4C2.89,20 2,19.11 2,18V6C2,4.89 2.89,4 4,4H20M8.5,15V9H7.25V12.5L4.75,9H3.5V15H4.75V11.5L7.3,15H8.5M13.5,10.26V9H9.5V15H13.5V13.75H11V12.64H13.5V11.38H11V10.26H13.5M20.5,14V9H19.25V13.5H18.13V10H16.88V13.5H15.75V9H14.5V14A1,1 0 0,0 15.5,15H19.5A1,1 0 0,0 20.5,14Z"
                      />
                    </svg>
                  </md-icon>
                  <p flex>New</p>
                </div>
              </md-button>
            </md-menu-item>
          </md-menu-content>
          </md-menu>
       </div>

      <div class="md-toolbar-tools" layout="row">
        <md-input-container flex>
          <input
            id="txtFind"
            type="text"
            ng-model="$ctrl.query"
            name="x"
            placeholder="Find reactions by tag..."
            ng-change="$ctrl.loadReactions(null, $ctrl.query);"
            ng-model-options="{updateOn: 'default blur',debounce: { 'default': 500, 'blur': 0 }}"
          />
        </md-input-container>
        <md-button class="md-icon-button" ng-click="$ctrl.clearQuery()">
          <md-icon>
            <svg id="close" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
              />
            </svg>
          </md-icon>
        </md-button>
        <md-button class="md-icon-button" ng-click="$ctrl.queryChanged()">
          <md-icon>
            <svg id="refresh" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M17.65,6.35C16.2,4.9 14.21,4 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20C15.73,20 18.84,17.45 19.73,14H17.65C16.83,16.33 14.61,18 12,18A6,6 0 0,1 6,12A6,6 0 0,1 12,6C13.66,6 15.14,6.69 16.22,7.78L13,11H20V4L17.65,6.35Z"
              />
            </svg>
          </md-icon>
        </md-button>
      </div>

    </md-toolbar>

    <md-list
      infinite-scroll="$ctrl.loadReactions()"
      infinite-scroll-distance="3"
      infinite-scroll-disabled="$ctrl.busy || $ctrl.noMore"
      ih-intecept-href
    >
      <md-list-item ng-repeat="item in $ctrl.reactions" href="/reactions/{{item.userImageId}}">
        <div class="lv-item media" layout="row" flex>
          <div flex="30">
            <img ng-src="{{item.imageUrl}}" />
          </div>
          <div class="media-body" flex>
            <div class="lv-small">ShortUrl: {{item.shortUrl}}</div>
            <div class="lv-small">Uploaded By: {{item.uploadedBy}}</div>
            <div class="lv-small">Tags:</div>
            <ul class="lv-attrs">
              <li ng-repeat="tag in item.tags">{{tag.value}}</li>
            </ul>
          </div>
          <md-menu md-position-mode="target-right target">
            <md-button aria-label="Open settings menu" class="md-icon-button" ng-click="$mdMenu.open($event)">
              <md-icon>
                <svg id="dots-vertical" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z"
                  />
                </svg>
              </md-icon>
            </md-button>
            <md-menu-content>
              <md-menu-item>
                <md-button ng-click="$ctrl.copyToClipboard(item)">
                  <div layout="row" flex>
                    <md-icon md-menu-align-target>
                      <svg id="content-copy" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
                        />
                      </svg>
                    </md-icon>
                    <p flex>Copy</p>
                  </div>
                </md-button>
              </md-menu-item>
              <md-menu-item>
                <md-button ng-click="$ctrl.deleteImage(item)">
                  <div layout="row" flex>

                    <md-icon md-menu-align-target>
                      <svg id="delete" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z"
                        />
                      </svg>
                    </md-icon>
                    <p flex>Delete</p>
                  </div>
                </md-button>
              </md-menu-item>
            </md-menu-content>
          </md-menu>
        </div>
      </md-list-item>
    </md-list>
  </div>

</div>
`,
  controller: [
    '$http',
    '$lazySnackbar',
    '$q',
    function ($http: angular.IHttpService, $lazySnackbar: LazySnackBarService, $q: angular.IQService): void {
      const $ctrl = this;
      $ctrl.imageType = 'all';

      $ctrl.noMore = false;
      $ctrl.itemCounter = 0;
      $ctrl.sort = 'popular';

      $ctrl.clearQuery = function () {
        $ctrl.loadReactions(null, '');
      };

      $ctrl.changeSort = function (sort) {
        $ctrl.sort = sort;
        $ctrl.loadReactions($ctrl.imageType);
      };

      let canceler;
      $ctrl.loadReactions = function (imageType, queryOverride) {
        if (imageType) {
          $ctrl.imageType = imageType;
          $ctrl.noMore = false;
          $ctrl.itemCounter = 0;
          $ctrl.reactions = null;
        }
        if (queryOverride != null) {
          $ctrl.query = queryOverride;
          $ctrl.noMore = false;
          $ctrl.itemCounter = 0;
          $ctrl.reactions = null;
        }
        if ($ctrl.busy || $ctrl.noMore) return;

        if (canceler) {
          canceler.resolve();
        }

        canceler = $q.defer();

        $ctrl.busy = true;
        $http
          .get<any>('/api/user/images', {
            params: {
              q: $ctrl.query,
              type: $ctrl.imageType,
              skip: $ctrl.itemCounter,
              limit: 50,
              sort: $ctrl.sort
            },
            timeout: canceler.promise
          })
          .then(
            function (resp) {
              $ctrl.busy = false;
              const data = resp.data;
              if (data.length === 0 || data.length < 10) $ctrl.noMore = true;
              $ctrl.itemCounter += data.length;
              $ctrl.reactions = ($ctrl.reactions || []).concat(data);
            },
            function (resp) {
              switch (resp.xhrStatus) {
                case 'abort':
                  // request cancelled, ignore
                  break;
                default:
                  $lazySnackbar.open('Unable to load reactions');
                  break;
              }
            }
          );
      };

      $ctrl.urlPasted = function (e) {
        // check if the url has a webp extension and if it does replace it
        // remove hashes
        if (
          e.originalEvent.clipboardData.getData('text').indexOf('.webp') !== -1 ||
          e.originalEvent.clipboardData.getData('text').match(/#.*$/)
        ) {
          $ctrl.newImage.imageUrl = e.originalEvent.clipboardData
            .getData('text')
            .replace('.webp', '.gif')
            .replace(/#.*$/, '');
          e.preventDefault();
          $ctrl.imageUrlChanged();
        }
      };

      $ctrl.copyToClipboard = function (image) {
        // check for a shortUrl
        if (!image.shortUrl) {
          // get one
          $http
            .post<any>('https://www.googleapis.com/urlshortener/v1/url?key=AIzaSyAan8tgcklQYFppYhRfXBaZ-Dj3sIqYBIM', {
              longUrl: image.imageUrl
            })
            .then(
              function (resp) {
                image.shortUrl = resp.data.id;
                $lazySnackbar.open('ShortUrl was missing. Try again.');

                // save the shortUrl back to DB
                $http.put('/api/user/images/' + image.userImageId + '/shortUrl', { shortUrl: image.shortUrl });
              },
              function () {
                $lazySnackbar.open('There was a problem getting a shortened url for this image');
              }
            );
        } else {
          // Create a "hidden" input
          const aux = document.createElement('input');

          // Assign it the value of the specified element
          aux.setAttribute('value', encodeURI(image.shortUrl));

          // Append it to the body
          document.body.appendChild(aux);

          // Highlight its content
          aux.select();

          // Copy the highlighted text
          try {
            // Now that we've selected the anchor text, execute the copy command
            const successful = document.execCommand('copy');
            const msg = successful ? 'successful' : 'unsuccessful';
            console.log('Copy shortUrl command was ' + msg);
          } catch (err) {
            console.log('Oops, unable to copy');
          }

          // Remove it from the body
          document.body.removeChild(aux);

          // show copied notification
          $lazySnackbar.open('Copied ' + image.shortUrl);

          // record the use, fire and forget
          $http.put<any>('/api/user/images/' + image.userImageId + '/used', {});
        }
      };

      $ctrl.deleteImage = function (image) {
        $http.delete('/api/user/images/' + image.userImageId).then(
          function (resp) {
            $ctrl.reactions.splice($ctrl.reactions.indexOf(image), 1);
          },
          function (resp) {
            alert('could not delete image');
          }
        );
      };
    }
  ]
};

export default ReactionListComponent;
