import { LazySnackBarService } from '@ih/services';

export default class ChannelOwnerTransferDialogService {
  static $inject = ['$mdDialog'];
  constructor(private $mdDialog) {}

  public show($event: MouseEvent, locals: any) {
    return this.$mdDialog.show({
      controller: [
        '$http',
        '$mdDialog',
        '$lazySnackbar',
        'campaign',
        'channel',
        function ($http: angular.IHttpService, $mdDialog, $lazySnackbar: LazySnackBarService) {
          const $ctrl = this;

          $ctrl.$onInit = () => {
            $ctrl.loadingMembers = true;
            $ctrl.channelMembers = null;
            $http
              .get<
                any[]
              >('/api/campaigns/' + $ctrl.campaign.campaign_id + '/channels/' + $ctrl.channel.channelId + '/members')
              .then(
                (resp) => {
                  $ctrl.loadingMembers = false;
                  const ownerIndex = resp.data.findIndex((m) => m.authorId === $ctrl.channel.owner.authorId);
                  if (ownerIndex !== -1) {
                    resp.data.splice(ownerIndex, 1);
                  }
                  $ctrl.channelMembers = resp.data;
                },
                () => {
                  $lazySnackbar.open('There was a problem getting the list of members');
                  $ctrl.loadingMembers = false;
                }
              );
          };

          $ctrl.selectMember = function (member) {
            $mdDialog.hide(member);
          };

          $ctrl.hide = function () {
            $mdDialog.cancel();
          };
        }
      ],
      controllerAs: '$ctrl',

      template: /* html */ `<md-dialog class="channel-owner-transfer">
  <md-toolbar>
    <div class="md-toolbar-tools">
      <md-button class="md-icon-button" ng-click="$ctrl.hide()">
        <md-icon aria-label="Close dialog">
          <svg id="close" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
            />
          </svg>
        </md-icon>
      </md-button>
      <h2>Transfer ownership of {{$ctrl.channel.name}}</h2>
    </div>
  </md-toolbar>
  <md-dialog-content layout="column">
    <div class="card-body table-responsive table-hover">
      <table class="table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr class="success">
            <td>{{$ctrl.channel.owner.firstName}}</td>
            <td>{{$ctrl.channel.owner.lastName}}</td>
            <td>{{$ctrl.channel.owner.email}}</td>
            <td><md-button ng-disabled="true">Current Owner</md-button></td>
          </tr>
          <tr ng-repeat="member in $ctrl.channelMembers track by member.authorId">
            <td>{{member.firstName || 'N/A'}}</td>
            <td>{{member.lastName || 'N/A'}}</td>
            <td>{{member.email}}</td>
            <td>
              <md-button ng-click="$ctrl.selectMember(member)">Make Owner</md-button>
            </td>
          </tr>
          <tr ng-if="$ctrl.loadingMembers">
            <td colspan="4" class="text-center">Loading...</td>
          </tr>
          <tr ng-if="!$ctrl.loadingMembers && $ctrl.channelMembers.length == 0">
            <td colspan="4" class="text-center">There are no admins</td>
          </tr>
        </tbody>
      </table>
    </div>
  </md-dialog-content>
</md-dialog>
`,
      targetEvent: $event,
      clickOutsideToClose: true,
      locals,
      bindToController: true,
      parent: window.document.body
    });
  }
}
