import { Injectable } from '@angular/core';
import { type MatDialogRef } from '@angular/material/dialog';
import { LazyDialogService } from '@ih/lazy-dialog';
import { type CreateAppDialogComponent } from './create-app-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class CreateAppDialogService {
  constructor(private lazyDialog: LazyDialogService) {}

  public async open(): Promise<MatDialogRef<CreateAppDialogComponent>> {
    const createAppDialogComponent = await import('./create-app-dialog.component').then(
      (m) => m.CreateAppDialogComponent
    );

    const dialog = await this.lazyDialog.getDialogService();

    return dialog.open(createAppDialogComponent, {
      panelClass: ['create-app-dialog', 'dialog-no-padding', 'dialog-fullscreen'],
      maxWidth: undefined
    });
  }
}
