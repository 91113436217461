import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export interface CurrentApp {
  name: string;
  slug: string;
  campaignId: number;
  archived: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SideNavService {
  private _currentApp$ = new BehaviorSubject<CurrentApp>(null);
  currentApp$ = this._currentApp$.asObservable();

  refreshApp$ = new Subject<void>();

  constructor(private http: HttpClient) {}

  setCurrentApp(campaignId: number): void {
    if (!campaignId) {
      this._currentApp$.next(null);
      return;
    }
    this.http.get<CurrentApp>(`/api/campaigns/${campaignId}/meta`).subscribe((app) => {
      this._currentApp$.next(app);
    });
  }
}
