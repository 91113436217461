import { CUSTOM_EVENTS } from '@ih/constants';

const appRun = [
  '$templateRequest',
  '$rootScope',
  '$http',
  function ($templateRequest: angular.ITemplateRequestService, $rootScope, $http: angular.IHttpService): void {
    // Pre-fetch icons sources by URL and cache in the $templateCache...
    // subsequent $templateRequest calls will look there first.

    const urls = ['https://cdn.jsdelivr.net/npm/@mdi/angular-material@6.6.96/mdi.svg'];

    urls.forEach(function (url) {
      $templateRequest(url);
    });

    const rootPathRegex = /^(\/\w+)$/i;
    const deepPathRegex = /^(\/[\w/]+)\/(?:[^/]+)$/i;

    $rootScope.$on('$locationChangeSuccess', function (event, newUrlStr, oldUrlStr) {
      document.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.ROUTE_CHANGED, { detail: { oldUrlStr, newUrlStr } }));
      const newUrl = new URL(newUrlStr);

      const rootMatches = newUrl.pathname.match(rootPathRegex);
      const deepMatches = newUrl.pathname.match(deepPathRegex);
      if (rootMatches) {
        $rootScope.upUrl = '/';
      } else if (deepMatches) {
        $rootScope.upUrl = deepMatches[1];
      } else {
        $rootScope.upUrl = null;
      }
    });
  }
];

export default appRun;
