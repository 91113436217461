import { Router } from '@angular/router';

appConfig.$inject = [
  '$mdIconProvider',
  '$routeProvider',
  '$compileProvider',
  '$mdThemingProvider',
  '$locationProvider',
  '$sceDelegateProvider'
];

function appConfig(
  $mdIconProvider,
  $routeProvider,
  $compileProvider,
  $mdThemingProvider,
  $locationProvider,
  $sceDelegateProvider
): void {
  $sceDelegateProvider.resourceUrlWhitelist([
    'self',
    'https://ih-cdn.ihub.app/**',
    'https://cdn.jsdelivr.net/npm/@mdi/**'
  ]);

  $mdIconProvider.defaultIconSet('https://cdn.jsdelivr.net/npm/@mdi/angular-material@6.6.96/mdi.svg');

  if (!window.campaign.debug) {
    $compileProvider.debugInfoEnabled(false);
    $compileProvider.commentDirectivesEnabled(false);
    $compileProvider.cssClassDirectivesEnabled(false);
  }

  $mdThemingProvider.definePalette('ihubBlue', {
    50: 'edf6fb',
    100: 'd2e7f6',
    200: 'b4d8f0',
    300: '96c8ea',
    400: '80bce6',
    500: '69b0e1',
    600: '61a9dd',
    700: '56a0d9',
    800: '4c97d5',
    900: '3b87cd',
    A100: 'ffffff',
    A200: 'e6f3ff',
    A400: 'b3d9ff',
    A700: '9acdff',
    contrastDefaultColor: 'light',
    contrastDarkColors: ['50', '100', '200', '300', '400', 'A100', 'A200', 'A400', 'A700'],
    contrastLightColors: ['500', '600', '700', '800', '900']
  });

  $mdThemingProvider.definePalette('ihubAccent', {
    50: 'e8eef2',
    100: 'c7d5e0',
    200: 'a1bacb',
    300: '7b9eb6',
    400: '5f89a6',
    500: '437496',
    600: '3d6c8e',
    700: '346183',
    800: '2c5779',
    900: '1e4468',
    A100: 'a6d2ff',
    A200: '73b8ff',
    A400: '409eff',
    A700: '2691ff',
    contrastDefaultColor: 'light',
    contrastDarkColors: ['50', '100', '200', '300', 'A100', 'A200', 'A400'],
    contrastLightColors: ['400', '500', '600', '700', '800', '900', 'A700']
  });

  $mdThemingProvider.theme('default').primaryPalette('ihubBlue').accentPalette('ihubAccent');

  $routeProvider.caseInsensitiveMatch = true;

  $routeProvider
    .when('/apps', {
      template: '<app-list></app-list>',
      reloadOnSearch: false
    })
    .when('/apps/:campaignId', {
      template: '<app-detail campaign-id="$resolve.campaignId"></app-detail>',
      reloadOnSearch: false,
      resolve: {
        campaignId: [
          '$route',
          function ($route) {
            return $route.current.params.campaignId;
          }
        ]
      }
    })
    .when('/apps/:campaignId/channels', {
      template: '<channel-list campaign-id="$resolve.campaignId"></channel-list>',
      reloadOnSearch: false,
      resolve: {
        campaignId: [
          '$route',
          function ($route) {
            return $route.current.params.campaignId;
          }
        ]
      }
    })
    .when('/apps/:campaignId/sms', {
      template: '<sms-log campaign-id="$resolve.campaignId"></sms-log>',
      resolve: {
        campaignId: [
          '$route',
          function ($route) {
            return $route.current.params.campaignId;
          }
        ]
      }
    })
    .when('/reactions', {
      template: '<reaction-list></reaction-list>',
      reloadOnSearch: false
    })
    .when('/reactions/:reactionId', {
      template: '<reaction-detail reaction-id="$resolve.reactionId"></reaction-detail>',
      resolve: {
        reactionId: [
          '$route',
          function ($route) {
            return $route.current.params.reactionId;
          }
        ]
      }
    })
    .when('/responses', {
      template: '<response-list></response-list>',
      reloadOnSearch: false
    })
    .when('/responses/:responseId', {
      template: '<response-detail response-id="$resolve.responseId"></response-detail>',
      resolve: {
        responseId: [
          '$route',
          function ($route) {
            return $route.current.params.responseId;
          }
        ]
      }
    })
    .when('/smsResponses', {
      template: '<sms-response-list></sms-response-list>',
      reloadOnSearch: false
    })
    .when('/smsResponses/:responseId', {
      template: '<sms-response-detail response-id="$resolve.responseId"></sms-response-detail>',
      resolve: {
        responseId: [
          '$route',
          function ($route) {
            return $route.current.params.responseId;
          }
        ]
      }
    })
    .when('/users', {
      template: '<user-list></user-list>',
      reloadOnSearch: false
    })
    .when('/users/:userId', {
      template: '<user-detail user-id="$resolve.userId"></user-detail>',
      resolve: {
        userId: [
          '$route',
          function ($route) {
            return $route.current.params.userId;
          }
        ]
      }
    })
    .when('/redirects', {
      template: '<redirect-list></redirect-list>',
      reloadOnSearch: false
    })
    .when('/redirects/:redirectId', {
      template: '<redirect-detail redirect-id="$resolve.redirectId"></redirect-detail>',
      resolve: {
        redirectId: [
          '$route',
          function ($route) {
            return $route.current.params.redirectId;
          }
        ]
      }
    })
    .when('/updates', {
      template: '<update-list></update-list>',
      reloadOnSearch: false
    })
    .when('/updates/:platformUpdateMessageId', {
      template: '<update-detail platform-update-message-id="$resolve.platformUpdateMessageId"></update-detail>',
      resolve: {
        platformUpdateMessageId: [
          '$route',
          function ($route) {
            return $route.current.params.platformUpdateMessageId;
          }
        ]
      }
    })
    .when('/press', {
      template: '<press-list></press-list>',
      reloadOnSearch: false
    })
    .when('/press/:pressReleaseId', {
      template: '<press-detail press-release-id="$resolve.pressReleaseId"></press-detail>',
      resolve: {
        pressReleaseId: [
          '$route',
          function ($route) {
            return $route.current.params.pressReleaseId;
          }
        ]
      }
    })
    .when('/account/', {
      template: '<my-profile></my-profile>'
    })
    .when('/', {
      template: '<home></home>'
    })
    .otherwise({
      controller: [
        '$location',
        '$router',
        function ($location: angular.ILocationService, $router: Router) {
          switch ($location.path()) {
            case '/account/logout':
              window.location.reload();
              break;
            default:
              $router.navigate(['']);
              break;
          }
        }
      ],
      template: '<h4>Loading...</h4>'
    });

  $locationProvider.html5Mode(true);
}

export default appConfig;
