import { Router } from '@angular/router';
import find from 'lodash-es/find';

declare const angular: angular.IAngularStatic;

const ReactionDetailComponent = {
  bindings: {
    reactionId: '<'
  },

  template: /* html */ `<div class="reaction-detail">
  <div class="page-body" md-whiteframe="4" layout="column">
    <md-toolbar class="md-accent" layout="row">
      <div class="md-toolbar-tools">
        <span flex>Edit reaction</span>
      </div>
    </md-toolbar>
    <div md-whiteframe="4">
      <form name="imageForm" ng-submit="$ctrl.saveImage()" layout="column" layout-padding>
        <img
          class="img-responsive"
          style="object-fit: contain"
          ng-class="$ctrl.orientationClass"
          ng-src="{{$ctrl.newImage.imageUrlPreview || 'https://placeholdit.imgix.net/~text?txtsize=23&txt=Your+image&w=600&h=300&txttrack=0'}}"
        />
        <div layout="row">
          <md-input-container flex>
            <input
              type="text"
              aria-label="Url"
              placeholder="URL for an external image"
              ng-model="$ctrl.newImage.imageUrl"
              ng-change="$ctrl.imageUrlChanged()"
              ng-paste="$ctrl.urlPasted($event)"
            />
          </md-input-container>
          <span flex="5" style="text-align: center">OR</span>
          <div class="fileinput fileinput-new" data-provides="fileinput">
            <div>
              <span
                class="btn btn-file"
                ng-class="{'btn-default':!$ctrl.newImage.imageUrl,'btn-warning':$ctrl.newImage.imageUrl}"
              >
                <span class="fileinput-new">{{$ctrl.newImage.imageUrl?'Change Image':'Select image'}}</span
                ><span class="fileinput-exists">Change</span><input type="file" name="fileImage" id="fileImage" />
              </span>
              <a href="#" class="btn btn-default fileinput-exists" data-dismiss="fileinput">Remove</a>
            </div>
          </div>
        </div>
        <md-chips
          ng-model="$ctrl.newImage.tags"
          md-transform-chip="$ctrl.newTag($chip)"
          md-add-on-blur="true"
          ng-change="$ctrl.checkDuplicates()"
        >
          <md-chip-template> {{$chip.value}} </md-chip-template>
        </md-chips>
        <div style="text-align: right">
          <md-button type="button" router-link="/reactions">Back</md-button>
          <md-button type="submit" class="md-raised md-primary" ng-disabled="$ctrl.savingImage">Save changes</md-button>
        </div>
      </form>
    </div>
  </div>
</div>
`,
  controller: [
    '$http',
    '$timeout',
    '$router',
    function ($http: angular.IHttpService, $timeout: angular.ITimeoutService, $router: Router) {
      const $ctrl = this;

      $ctrl.$onInit = function (): void {
        if ($ctrl.reactionId === 'new') {
          $ctrl.newImage = { tags: [] };
        } else {
          $http.get('/api/user/images/' + $ctrl.reactionId).then(function (resp) {
            $ctrl.newImage = resp.data;
            $ctrl.newImage.imageUrl = $ctrl.newImage.imageUrl.startsWith('//')
              ? 'https:' + $ctrl.newImage.imageUrl
              : $ctrl.newImage.imageUrl;
            $ctrl.newImage.imageUrlPreview = $ctrl.newImage.imageUrl;
          });
        }
      };

      $ctrl.saveImage = function (): void {
        if (
          $ctrl.newImage.imageUrl &&
          ($ctrl.newImage.imageUrl.indexOf('.webp') !== -1 || $ctrl.newImage.imageUrl.match(/#.*$/))
        ) {
          $ctrl.newImage.imageUrl = $ctrl.newImage.imageUrl.replace('.webp', '.gif').replace(/#.*$/, '');
        }

        const fd = new FormData();
        fd.append('file', ($('#fileImage')[0] as HTMLInputElement).files[0]);
        fd.append('imageJson', JSON.stringify($ctrl.newImage));
        $ctrl.savingImage = true;
        if (!$ctrl.newImage.userImageId) {
          $http
            .post('/api/user/images', fd, {
              transformRequest: angular.identity,
              headers: { 'Content-Type': undefined }
            })
            .then(
              function (resp) {
                $ctrl.savingImage = false;
                $router.navigateByUrl('/reactions');
              },
              function (resp) {
                $ctrl.savingImage = false;
                alert('There was a problem saving the article');
              }
            );
        } else {
          $http
            .put('/api/user/images/' + $ctrl.newImage.userImageId, fd, {
              transformRequest: angular.identity,
              headers: { 'Content-Type': undefined }
            })
            .then(
              function () {
                $ctrl.savingImage = false;
                $router.navigateByUrl('/reactions');
              },
              function () {
                $ctrl.savingImage = false;
                alert('There was a problem saving the article');
              }
            );
        }
      };

      $ctrl.imageUrlChanged = function (): void {
        $ctrl.orientationClass = null;
        $ctrl.newImage.imageUrlPreview = $ctrl.newImage.imageUrl;
      };

      $ctrl.newTag = function (value): any {
        // check for duplicates
        const match = find($ctrl.newImage.tags, { value });
        if (!match) {
          return { value };
        }

        return null;
      };

      // check the filename
      $('#fileImage').on('change.bs.fileinput', function (e) {
        const input: any = e.target;
        if (input.files && input.files[0]) {
          if (!/\.(gif|jpg|jpeg|tiff|png)$/i.test(input.files[0].name)) {
            alert('Not a valid image. Please upload .gif, .jpg, .png');
            // not an image
            $(input).fileupload('clear');
          } else {
            const reader = new FileReader();
            reader.onload = function (e): void {
              $timeout(function () {
                $ctrl.newImage.imageUrlPreview = e.target.result;
              });
            };
            reader.readAsDataURL(input.files[0]);
          }
        } else {
          $timeout(function () {
            $ctrl.orientationClass = null;
            $ctrl.newImage.imageUrlPreview = $ctrl.newImage.imageUrl;
          });
        }
      });
    }
  ]
};

export default ReactionDetailComponent;
