import { getChannelMdiIconSvg } from '@ih/utilities';
import each from 'lodash-es/each';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import findIndex from 'lodash-es/findIndex';
import map from 'lodash-es/map';
import remove from 'lodash-es/remove';
import some from 'lodash-es/some';
import uniqBy from 'lodash-es/uniqBy';

declare let angular: angular.IAngularStatic;

ChannelPickerDirective.$inject = ['$mdPanel', '$lazySnackbar'];
function ChannelPickerDirective($mdPanel, $lazySnackbar) {
  return {
    restrict: 'E',

    template: /* html */ `﻿<div class="channels select-picker dropdown">
  <md-button
    class="channelPickerButton"
    ng-class="{'md-raised': !showFlat}"
    type="button"
    ng-click="showChannelPickerPanel($event)"
    ng-disabled="ngDisabled"
  >
    <span class="text-muted" ng-show="groupList.length == 0">Please select a channel</span>
    <span ng-show="groupList.length > 0">
      <md-icon class="mdi-18px" ng-bind-html="channelIcon(groupList[0]) | toTrustedHtml"></md-icon>
      {{groupList[0].name}}</span
    >
    <span ng-show="groupList.length > 1">
      and ({{groupList.length - 1}}) other<span ng-show="groupList.length - 1 > 1">s</span></span
    >
    <span class="caret"></span>
  </md-button>
</div>
`,
    require: 'ngModel',
    scope: {
      onChange: '&',
      contentType: '@',
      ngRequired: '=',
      ngDisabled: '=',
      showFlat: '=',
      campaignId: '@'
    },
    link: function (scope, element, attrs, ngModel) {
      scope.channelIcon = getChannelMdiIconSvg;

      ngModel.$validators.required = function (modelValue, viewValue) {
        //
        if (scope.ngRequired) {
          const value = modelValue || viewValue;
          return value && value.length > 0;
        }
        return true;
      };

      // trigger validation on array changes
      scope.$watch(
        function () {
          return ngModel.$modelValue && ngModel.$modelValue.length;
        },
        function () {
          ngModel.$validate();
        }
      );

      // when the ngModel gets set store the value in groupList for use in the view
      ngModel.$formatters.push(function (value) {
        scope.groupList = value;
      });

      const onChange = function (groupList) {
        ngModel.$setViewValue(groupList);
        ngModel.$validate();
        if (scope.onChange) scope.onChange();
      };

      scope.showChannelPickerPanel = function (ev) {
        const position = $mdPanel
          .newPanelPosition()
          .relativeTo(element.find('.select-picker > .md-button'))
          .addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.ALIGN_TOPS);

        $mdPanel.open({
          attachTo: angular.element(document.body),
          controller: [
            '$scope',
            '$http',
            '$q',
            '$timeout',
            '$lazySnackbar',
            'settings',
            function ($scope, $http, $q, $timeout, $lazySnackbar, settings) {
              if (settings.groupList == null) {
                throw new Error('groupList cannot be null');
              }

              $scope.channelIcon = getChannelMdiIconSvg;

              $scope.initialChannels = null;

              $scope.search = function () {
                $scope.skip = 0;
                $scope.remaining = null;
                $scope.channels = null;
                $scope.getMore();
              };

              $scope.getMore = function () {
                // cancel the last request if there was one
                if ($scope.canceler) {
                  $scope.canceler.resolve();
                  $scope.canceler = null;
                }
                if ($scope.remaining == null || $scope.remaining > 0) {
                  $scope.canceler = $q.defer();

                  $scope.searching = true;
                  const channels = uniqBy(($scope.initialChannels || []).concat(settings.groupList || []), 'group_id');
                  const groupIdList = map(channels, 'group_id');
                  return $http
                    .get('/api/channels/' + settings.campaignId, {
                      params: {
                        q: $scope.query,
                        skip: $scope.skip,
                        limit: 50,
                        suspend: false,
                        exclude: groupIdList.join(',')
                      },
                      timeout: $scope.canceler.promise
                    })
                    .then(
                      function (resp) {
                        $scope.skip += resp.data.items.length;
                        $scope.channels = ($scope.channels || []).concat(resp.data.items);
                        $scope.remaining = resp.data.remaining;
                        $scope.searching = false;
                      },
                      function (ex) {
                        if (!$scope.canceler) $scope.searching = false;
                        if (ex.status !== -1) {
                          $lazySnackbar.open('We were unable to search for channels for some reason');
                        }
                      }
                    );
                }
              };

              $scope.clearSelection = function () {
                $scope.initialChannels = filter($scope.initialChannels, { isChannelOwner: true });

                remove(settings.groupList);

                each($scope.channels, function (item) {
                  if (!item.isChannelOwner) item.checked = false;
                });

                if (settings.onChange) settings.onChange(settings.groupList);
              };

              $scope.hasChannelsSelected = function () {
                return some($scope.initialChannels, 'checked') || some($scope.channels, 'checked');
              };

              $scope.getFilteredChannels = function () {
                return filter($scope.channels, function (item) {
                  return !find($scope.initialChannels, { group_id: item.group_id });
                });
              };

              $scope.min = Math.min;

              $scope.select = function (channel) {
                if (channel.checked) {
                  settings.groupList.push(channel);
                } else {
                  const index = findIndex(settings.groupList, { group_id: channel.group_id });

                  settings.groupList.splice(index, 1);
                }
                if (settings.onChange) settings.onChange(settings.groupList);
              };

              $scope.query = null;
              $scope.initialChannels = filter(settings.groupList, function (item) {
                return item;
              });

              // clear any checks from last selection
              each($scope.channels, function (item) {
                item.checked = false;
              });
              // make sure the initial channels are checked
              each($scope.initialChannels, function (item) {
                item.checked = true;
                if (item.owner_id === parseInt(settings.disableChannelOwner)) item.isChannelOwner = true;
              });

              if (!$scope.channels) {
                $scope.search();
              }
            }
          ],
          controllerAs: '$ctrl',

          template: /* html */ `<div class="search" layout="row">
  <md-input-container flex>
    <input
      type="search"
      placeholder="Find a channel..."
      ng-model="query"
      ng-change="search()"
      ng-model-options="{ updateOn: 'default blur', debounce:{ 'default': 500, 'blur': 0 } }"
      ng-keyup="cancel($event)"
    />
  </md-input-container>
</div>
<div class="scroll-container">
  <ul
    infinite-scroll="getMore()"
    infinite-scroll-distance="3"
    infinite-scroll-immediate-check="false"
    infinite-scroll-disabled="searching"
    infinite-scroll-container="'.channel-picker-panel > .scroll-container'"
  >
    <li class="clear" ng-if="hasChannelsSelected() && !query">
      <a href="" ng-click="clearSelection()">Clear selected</a>
    </li>
    <li ng-repeat="channel in initialChannels" title="{{channel.handle? '@' + channel.handle:''}}">
      <label>
        <input
          type="checkbox"
          ng-model="channel.checked"
          ng-change="select(channel)"
          ng-disabled="channel.isChannelOwner"
        />
        <md-icon ng-bind-html="channelIcon(channel) | toTrustedHtml"></md-icon>
        <span flex ng-bind-html="channel.name| highlight: query | toTrustedHtml"></span>
      </label>
    </li>
    <li role="separator" class="divider" ng-show="initialChannels.length > 0"></li>
    <li ng-repeat="channel in getFilteredChannels()" title="{{channel.handle? '@' + channel.handle:''}}">
      <label>
        <input type="checkbox" ng-model="channel.checked" ng-change="select(channel)" />
        <md-icon class="mdi-18px" ng-bind-html="channelIcon(channel) | toTrustedHtml"></md-icon>
        <span ng-bind-html="channel.name"></span>
      </label>
    </li>
    <li class="not-found" ng-show="channels.length == 0">Sorry we couldn't find that</li>
    <li layout="row" layout-align="center" ng-if="searching" style="padding: 5px">
      <md-progress-circular md-diameter="15" md-mode="indeterminate"></md-progress-circular>
    </li>
  </ul>
</div>
`,
          panelClass: 'channel-picker-panel',
          position,
          locals: {
            settings: {
              onChange,
              contentType: scope.contentType,
              groupList: ngModel.$viewValue || ngModel.$modelValue,
              campaignId: scope.campaignId
            }
          },
          openFrom: ev,
          clickOutsideToClose: true,
          escapeToClose: true,
          focusOnOpen: false
        });
      };
    }
  };
}

export default ChannelPickerDirective;
