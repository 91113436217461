import { Router } from '@angular/router';
import { LazySnackBarService } from '@ih/services';

const UserDetailComponent = {
  bindings: {
    redirectId: '<'
  },

  template: /* html */ `<div class="user-detail">
  <div class="page-body" md-whiteframe="4" layout="column">
    <md-toolbar class="md-accent" layout="row">
      <div class="md-toolbar-tools">
        <span flex>Create User</span>
      </div>
    </md-toolbar>
    <div md-whiteframe="4">
      <form name="newUserEditor" ng-submit="$ctrl.addUser()" layout="column" layout-padding>
        <md-input-container>
          <label for="email">First name</label>
          <input
            class="form-control"
            id="firstName"
            name="firstName"
            placeholder="First name"
            type="text"
            ng-model="$ctrl.newUser.firstName"
            required
          />
        </md-input-container>
        <md-input-container>
          <label for="email">Last name</label>
          <input
            class="form-control"
            id="lastName"
            name="lastName"
            placeholder="Last name"
            type="text"
            ng-model="$ctrl.newUser.lastName"
            required
          />
        </md-input-container>
        <md-input-container>
          <label for="email">Email address</label>
          <input
            class="form-control"
            data-val="true"
            data-val-email="The email field is not a valid e-mail address."
            data-val-required="The email field is required."
            id="email"
            name="email"
            placeholder="Email Address"
            type="text"
            ng-model="$ctrl.newUser.email"
            required
          />
        </md-input-container>
        <div style="text-align: right">
          <md-button type="button" router-link="/users">Back</md-button>
          <md-button type="submit" class="md-raised md-primary" ng-disabled="$ctrl.adding">Add User</md-button>
        </div>
      </form>
    </div>
  </div>
</div>
`,
  controller: [
    '$http',
    '$router',
    '$lazySnackbar',
    function ($http: angular.IHttpService, $router: Router, $lazySnackbar: LazySnackBarService): void {
      const $ctrl = this;

      $ctrl.$onInit = function () {
        if ($ctrl.redirectId === 'new') {
          $ctrl.newRedirect = {};
        } else {
          // u wot m8
        }
      };

      $ctrl.addUser = function () {
        if ($ctrl.newUser.email) {
          $ctrl.adding = true;
          $http.post('/api/users', $ctrl.newUser).then(
            function () {
              $ctrl.adding = false;
              $lazySnackbar.open('User ' + $ctrl.newUser.email + ' added');
              $router.navigate(['users']);
            },
            function () {
              $ctrl.adding = false;
              alert('There was a problem adding the user');
            }
          );
        }
      };
    }
  ]
};

export default UserDetailComponent;
