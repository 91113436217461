import { Router } from '@angular/router';

const RouterLinkDirective = [
  '$router',
  function ($router: Router): angular.IDirective {
    return {
      link: function (scope, element, attrs) {
        element.attr('href', attrs.routerLink);

        function onClick(event: JQuery.Event): void {
          event.preventDefault();

          $router.navigateByUrl(attrs.routerLink);
        }

        element.on('click', onClick);

        scope.$on('$destroy', () => {
          element.off('click', onClick);
        });
      }
    };
  }
];

export default RouterLinkDirective;
